import React, { useContext } from 'react';
import Link from 'gatsby';
import Github from '../svgs/github.svg';
import Linkedin from '../svgs/linkedin.svg';
import Email from '../svgs/email.svg';
import { ThreeContext } from '../three/ThreeContext';

const FooterCta = () => {
  const { threeStarter} = useContext(ThreeContext);
  return (
    <div className='footer-cta ptb-xl'>
      <h2 className='cta-prompt'>Like what you see?</h2>
      <div className="cta-links">
        <h3>Hire Me!</h3>
          <div className="social-icons">
            { threeStarter && <a href={`mailto:alexalhamdan@gmail.com?subject=Outreach from alexalhamdan.com`}><Email /></a> }
            <a target='_blank' rel='noopener noreferrer' href='https://www.linkedin.com/in/alexalhamdan/'><Linkedin /></a>
            <a target='_blank' rel='noopener noreferrer'  href='https://github.com/alx-alh'><Github /></a>
          </div>
      </div>
    </div>
  )
}
export default FooterCta;