
import React, { useEffect, useContext } from "react";
import { graphql, useStaticQuery} from 'gatsby';
import Img from 'gatsby-image';
import testImg2 from '../images/teammarketing.jpg';
import { useSpring, animated } from 'react-spring';
import {ThreeContext} from '../three/ThreeContext';
import ProjectHeaderAlt from '../components/ProjectHeaderAlt';
import ProjectFooter from '../components/ProjectFooter';
import ContentImageSplit from '../components/ContentImageSplit';
import CenterContent from '../components/CenterContent';


export const projectData =   {
  name: "Team Marketing Report",
  client: "Team Marketing Report",
  url: "/projects/team-marketing-report",
  order: 4,
  excerpt: "Lorem Ipsum Excerpt goes here, keep it short",
  image: testImg2,
  listData: [
    {label: 'client', value:"Team Marketing Report"},
    {label: 'services', value:"Full-Stack Development, Web Design"},
    {label: 'products', value:"Web Application, Marketing Site"},
    {label: 'technologies', value:"HTML, CSS, JS, React.js, PHP"},
  ],
  services: "Full-Stack Development, UX & UI Design",
  color: '#ee4036',
};
  
const TeamMarketing = props => {
  let pageMount = props.mount;

  const query = useStaticQuery(graphql`
    query tmrImages {
      tmrIntro: file(name: {eq: "tmr_intro"}) {
        id
        childImageSharp {
          fluid{
            ...GatsbyImageSharpFluid
          }
        }
      },
      responsiveScreens: allFile(filter: {name: {regex: "/respons/"}}) {
        edges {
          node {
            id
            childImageSharp {
              id
              fluid(maxWidth: 752, maxHeight: 1624){
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      },
      homeImage: file(name: {eq: "01_tmr_home"}) {
        id
        childImageSharp {
          fluid{
            ...GatsbyImageSharpFluid
          }
        }
      },
      dashImage: file(name: {eq: "04_tmr_dashboard"}) {
        id
        childImageSharp {
          fluid{
            ...GatsbyImageSharpFluid
          }
        }
      },
      factbookImage: file(name: {eq: "05_tmr_factbook"}) {
        id
        childImageSharp {
          fluid{
            ...GatsbyImageSharpFluid
          }
        }
      },
      factbookDetailImage: file(name: {eq: "06_tmr_factbook_detail"}) {
        id
        childImageSharp {
          fluid{
            ...GatsbyImageSharpFluid
          }
        }
      },
      fciImage: file(name: {eq: "07_tmr_fci"}) {
        id
        childImageSharp {
          fluid{
            ...GatsbyImageSharpFluid
          }
        }
      },
      fciDetailImage: file(name: {eq: "08_tmr_fci_detail"}) {
        id
        childImageSharp {
          fluid{
            ...GatsbyImageSharpFluid
          }
        }
      },
      insiderImage: file(name: {eq: "09_tmr_insider"}) {
        id
        childImageSharp {
          fluid{
            ...GatsbyImageSharpFluid
          }
        }
      },
      footerImage: file(name: {eq: "tempest"}) {
        id
        childImageSharp {
          fluid{
            ...GatsbyImageSharpFluid
          }
        }
      }
      ogImage: file(name: {eq: "teammarketing"}) {
        id
        childImageSharp {
          fluid{
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)



  const springProps = useSpring({
    opacity: pageMount ? 1 : 0,
    from: {
      opacity: 0
    }
  });


  return(

    <animated.div style={{opacity: springProps.opacity }} id="detail-container" className={"project-detail"}>
      <ProjectHeaderAlt location={props.location} ogImage={props.data.ogImage.childImageSharp.fluid.src} {...projectData} pageMount={pageMount}>
        <p>Team Marketing Report is a leading source of valuable sponsorship data for sports marketing professionals. Following a change of ownership and a re-branding, I was approached to lead the design / development of their new web application and marketing site. This also required a restructuring of their previous database, which contained more than 100,000 datapoints, and a content management system that would allow for their continuous growth.</p>
      </ProjectHeaderAlt>
      <ContentImageSplit orderSwitch={true} /* backgroundColor="rgba(255,255,255, 0.1)" */ skew="10deg" translateY="-40%" image={props.data.tmrIntro.childImageSharp.fluid} skewColor="rgba(255,255,255, 0.025)"  subheading="design inspiration" heading="Rules<br />& Regulations">
        <p>In seeking inspiration by analyzing the sports industry as a whole, it's clear that one of the most defining aspects of conventional sports is the presence of rules. Without rules and regulations, there are no clear-cut winners or losers. For a majority of sports; rules and regulations are visually represented in the form of field markings and scoreboards.</p>
      </ContentImageSplit>
      <CenterContent subheading={"solution"} heading={"Tying it all together"}>
        <p>By using visual motifs similar to field markings and block jersey numbers, the final marketing site and application have an aesthetic that is instantly recognizable and familiar for any sports professional. The marketing site uses WordPress as a content management system, and is supplemented by a custom backend which uses React and the WP-JSON API. The web application is run with a tiered subscription model, opening up more information for each of their product offerings: the FactBook, FanCostIndex, and TMR Insider.</p>
      </CenterContent>
      <div className="container-fluid img-flow">
        <div className="row">
          <div className="col-md-9 mx-auto">
            <Img fluid={props.data.homeImage.childImageSharp.fluid} />
          </div>
          <div className="col-md-6 mr-auto">
            <Img fluid={props.data.dashImage.childImageSharp.fluid} />
          </div>
          <div className="content-wrap col-md-6">
            <div className="content ml-auto">
              <h4 className="subheading">UX / UI - Dashboard</h4>
              <h2>Dashboard</h2>
              <p>To ensure users don't feel overwhelmed with the data, I designed a dashboard that serves as a quick starting point, highlighting new and popular content from each of TMR's main product offerings.</p>
            </div>
          </div>
        </div>
      </div>
      <ContentImageSplit /* backgroundColor="rgba(255,255,255, 0.1)" */ skew="10deg" translateY="-40%" image={props.data.factbookImage.childImageSharp.fluid} skewColor="rgba(255,255,255, 0.025)"  subheading="Product offering 01" heading="FactBook">
        <p>TMR's most valuable product offering is the FactBook: an expansive database of contact and sponsorship information for teams, leagues, universities, agencies, and other entities. Subscribers can find crucial data that helps drive major marketing decisions in the $500 billion+ sports industry.</p>
      </ContentImageSplit>
      <div className="container img-flow">
        <div className="row">
          <div className="col-md-9 mx-auto">
            <Img fluid={props.data.factbookDetailImage.childImageSharp.fluid} />
          </div>
          <div className="col-md-6 mr-auto">
            <Img fluid={props.data.fciImage.childImageSharp.fluid} />
          </div>
          <div className="content-wrap col-md-6">
            <div className="content ml-auto">
              <h4 className="subheading">Product Offering 02</h4>
              <h2>Fan Cost Index</h2>
              <p>TMR's FanCostIndex is a unique product offering first created by Team Marketing Report 20 years ago. The primary datapoint answers the question: "How much does it cost for a family of four to attend a game for a given team?" I developed a solution that allows subscribers to quickly compare leagues as well as individual teams.</p>
            </div>
          </div>
        </div>
      </div>
      <ContentImageSplit /* backgroundColor="rgba(255,255,255, 0.1)" */ skew="10deg" translateY="-40%" image={props.data.insiderImage.childImageSharp.fluid} skewColor="rgba(255,255,255, 0.025)"  subheading="Product offering 03" heading="TMR Insider">
        <p>To supplement all of the data provided with the FactBook and FanCostIndex, the TMR Insider provides current sports news and editorial content. Subscribers can stay up to date with all of the latest deals and major events in sports, establishing TMR as a crucial authoritative resource to guide sports professionals.</p>
      </ContentImageSplit>
      

      <ProjectFooter image={props.data.footerImage.childImageSharp.fluid} />
    </animated.div>

  )
};

export default TeamMarketing;


