import * as THREE from 'three';
import RotatingIndexAwait from './RotatingIndexAwait';


// window.THREE = THREE;

async function SceneManagerWrap(canvas){
 //console.log('SMWrap Start');

  const sceneManager = await new SceneManagerLaunch(canvas);
 //console.log(sceneManager);
 //console.log('SMWrap end');
  return(sceneManager)

}

export default canvas => {
  
  return new Promise( resolve => {
   //console.log('smStart');

    async function buildAll(){


     //console.log('SMLaunch Start');
        
      const clock = new THREE.Clock();
      const origin = new THREE.Vector3(0,0,0);
    
      const screenDimensions = {
        //for clicking scale to work, had to set following based on window
        width: window.innerWidth,
        height: window.innerHeight
        // width: canvas.width,
        // height: canvas.height
      }
    
      const mousePosition = {
        x: 0,
        y: 0
      }
    
      const scene = buildScene();
      const renderer = buildRender(screenDimensions);
      const camera = buildCamera(screenDimensions);
      const sceneSubjects = await RotatingIndexAwait({scene, camera});
      // const sceneSubjects = await createSceneSubjects(scene);

      // window.scene = scene;
        
      function buildScene() {
        const scene = new THREE.Scene();
        // scene.background = new THREE.Color("#FFF");
    
        return scene;
      }
    
      function buildRender({ width, height }) {
        const renderer = new THREE.WebGLRenderer({ canvas: canvas, antialias: true, alpha: true });
        const DPR = window.devicePixelRatio ? window.devicePixelRatio : 1;
        renderer.setPixelRatio(DPR);
        renderer.setSize( window.innerWidth, window.innerHeight);
    
    
        return renderer;
      }
    
      function buildCamera({ width, height }) {
        const aspectRatio = width / height;
        const fieldOfView = 40;
        const nearPlane = 0.1;
        const farPlane = 300;
        const camera = new THREE.PerspectiveCamera(fieldOfView, aspectRatio, nearPlane, farPlane);
    
        camera.position.set(0,0,3);
    
        window.camera = camera;
    
        return camera;
      }
    
      function createSceneSubjects(scene, color) {
        return new Promise(resolve =>{
    
          const sceneSubjects = [
            new RotatingIndexAwait(),
          ]
    
          resolve(sceneSubjects);
        })
        //   const sceneSubjects = [
        //     new RotatingIndex({scene, camera})
        //   ];
        //  //console.log('creating scene subjects')
        //   resolve(sceneSubjects);
        //  //console.log('created scene subjects')
        //   return;
        // })
        
        // const sceneSubjects = [
        //   new RotatingIndex({scene, camera}),
        //   // new GeneralLights(scene),
        //   // new GridToFullscreen({scene, camera}),
        //   // new TriGrid({scene, camera, canvas, renderer, mousePosition}),
        //   // new BoxGrid({scene, camera, canvas, renderer, mousePosition}),
        //   // new TextTest({scene}),
        //   // new AlignTest(scene),
        //   // new SceneSubject({scene, color, camera, renderer, canvas}),
        // ];
        // window.sss = sceneSubjects;
        // return sceneSubjects;
      }
    
      function update({time}) {
        const elapsedTime = clock.getElapsedTime();
    
        // for(let i=0; i<sceneSubjects.length; i++)
          sceneSubjects.update(time);
    
        // updateCameraPositionRelativeToMouse();
    
        renderer.render(scene, camera);
      }
    
      // function changeColor(color) {
      //   sceneSubjects[1].changeColor(color);
      // }
    
      // function updateCameraPositionRelativeToMouse() {
      //   camera.position.x += (  (mousePosition.x * 0.1) - camera.position.x ) * 0.001;
      //   camera.position.y += ( -(mousePosition.y * 0.1) - camera.position.y ) * 0.001;
      //   camera.lookAt(origin);
      // }
    
      function onWindowResize() {
        const { width, height } = canvas;
    
        screenDimensions.width = width;
        screenDimensions.height = height;
    
        camera.aspect = width / height;
        camera.updateProjectionMatrix();
    
        renderer.setSize(width, height);
      }
    
      // let raycaster = new THREE.Raycaster();
      let mouse3d = new THREE.Vector2();
    
    
      function onMouseMove(x, y) {
        mousePosition.x = x;
        mousePosition.y = y;
        // if(mouse3d !== undefined) {
        mouse3d.x = (x / screenDimensions.width) * 2 - 1;
        mouse3d.y = -(y / screenDimensions.height) * 2 + 1;
        
        // }
          ////console.log(mouse3d);
    
      }
     //console.log('SMLaunch Promise  End');

      
      resolve({
        update,
        onWindowResize,
        onMouseMove,
        scene,
        sceneSubjects
      })
    }
    buildAll();


  })
  
}

// export default props => {
//   return new Promise(resolve => {

//     setTimeout( async () => {
//      //console.log('timeout hit');
//       const testRotate = await RotatingIndexAwait();
//       resolve(testRotate);
//     }, 2000);

//   })
// }

// export default SceneManagerWrap
