import React, { useEffect, useContext, useState } from "react";
import { graphql, useStaticQuery} from 'gatsby';
import {ThreeContext} from '../three/ThreeContext';
import { useSpring, animated } from 'react-spring';
import testImg2 from '../images/r_barc.jpg';
import ProjectHeaderAlt from '../components/ProjectHeaderAlt';
import TwoColumnText from '../components/TwoColumnText';
import MockupGrid from '../components/MockupGrid';
import ContentImageSplit from '../components/ContentImageSplit';
import CenterContent from '../components/CenterContent';
import SidebarContent from '../components/SidebarContent';
import CenterMedia from '../components/CenterMedia';
import MediaCarousel from '../components/MediaCarousel';
import SlickCarousel from '../components/SlickCarousel';
import AngledMockupsContent from '../components/AngledMockupsContent';
import HoverImageSplit from '../components/HoverImageSplit';
import ProjectFooter from '../components/ProjectFooter';
import vidPoster from '../images/project-content/ross-barney-architects/desktop/places_screenshot__0000.jpg';




export const projectData = {
  name: "Ross Barney Architects",
  client: "Ross Barney Architects",
  url: "/projects/ross-barney-architects",
  order: 2,
  excerpt: "A digital interpretation of the architectural catalog.",
  image: testImg2,
  listData: [
    {label: 'client', value:"Ross Barney Architects"},
    {label: 'services', value:"Full-Stack Development, Web Design"},
    {label: 'products', value:"Portfolio Site"},
    {label: 'technologies', value:"HTML, CSS, Bootstrap, JS, PHP"},
  ],
  services: "Full-Stack Development, UX & UI Design",
  color: '#8dc63f',
};


  
const RossBarney = props => {

  const query = useStaticQuery(graphql`
    query rbaImages {
      projectImages: allFile(filter: {relativeDirectory: {regex: "/ross-barney-architects/desktop/"}}, sort: {fields: name, order: ASC}) {
        edges {
          node {
            id
            name
            childImageSharp {
              id
              fluid (maxWidth:2000){
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      },
      responsiveScreens: allFile(filter: {relativeDirectory: {regex: "/rbarc_responsive/"}}) {
        edges {
          node {
            id
            childImageSharp {
              id
              fluid(maxWidth: 752, maxHeight: 1624){
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      },
      bookCaseImage: file(name: {eq: "r_barc_bookcase"}) {
        id
        childImageSharp {
          fluid{
            ...GatsbyImageSharpFluid
          }
        }
      }
      ogImage: file(name: {eq: "r_barc"}) {
        id
        childImageSharp {
          fluid{
            ...GatsbyImageSharpFluid
          }
        }
      }
      peopleImage: file(name: {eq: "people_placeholder"}) {
        id
        childImageSharp {
          fluid{
            ...GatsbyImageSharpFluid
          }
        }
      }
      footerImage: file(name: {eq: "picture_north"}) {
        id
        childImageSharp {
          fluid{
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  
  const {pNum} = useContext(ThreeContext);
  let pageMount = props.mount;

  let images = props.data.projectImages.edges;
  let responsiveImages = props.data.responsiveScreens.edges;

  const springProps = useSpring({
    opacity: pageMount ? 1 : 0,
    from: {
      opacity: 0
    }
  });

  const description = 'Ross Barney Architects, one of the most renown Chicago-based architecture firms, needed an updated website that would serve as a showcase for their ever-increasing portfolio of work.'

  return(
    <>
      <animated.div style={{opacity: springProps.opacity }} id="detail-container" className={"project-detail"}>
      <ProjectHeaderAlt location={props.location} ogImage={props.data.ogImage.childImageSharp.fluid.src} {...projectData} pageMount={pageMount}>
          <p>Ross Barney Architects, one of the most renowned Chicago-based architecture firms, needed an updated website that would serve as a showcase for their ever-increasing portfolio of work. Through the varied aesthetic of their projects, curiosity and experimentation are found to be the common thread. In keeping with their company values, I designed and developed a site that encourages curiosity and playfulness while maintaining professionalism.</p>
        </ProjectHeaderAlt>
        <ContentImageSplit orderSwitch={true} centerAlign={true} /* backgroundColor="rgba(255,255,255, 0.1)" */ skew="10deg" translateY="-10%" image={props.data.bookCaseImage.childImageSharp.fluid} skewColor="rgba(255,255,255, 0.025)"  subheading="design inspiration" heading="Making New of<br />old traditions">
          <p>Before the internet grew to be more accessible, architects traditionally showcased their work in large architectural catalogs. It's common to find that architectural firms often have their own libraries filled with material samples, product catalogs, and architectural portfolios; not just for displaying their own work, but for seeking inspiration in the work of others.</p>
        </ContentImageSplit>
        <CenterContent subheading={"solution"} heading={"The Digital Catalog"}>
          <p>Working closely with Ross Barney Architects, we created a digital interpretation of the architectural catalog. Visitors navigate the site in a manner reminiscent of flipping through the pages of a book. Project categories emulate book spines on the shelf, and one views an individual project's images as though flipping through pages.</p>
        </CenterContent>
        <CenterMedia placeholder={vidPoster} videoSrc="https://player.vimeo.com/external/397022017.hd.mp4?s=2acd7e4540fdacf054f1c099ddc52844e9b096a4&profile_id=175" /* backgroundColor="#dddddd" */ />
        <ContentImageSplit backgroundColor="rgba(255,255,255,0.025)" translateY="-10%" image={null} subheading="UX / UI - Places" heading="Thumbing<br />Through" imgHidden={true} >
          <p>To aid the user experience of viewing their large body of work, which contains a wide variety of project types, projects were categorized, providing an intuitive hierarchical flow through the site. At each level, users browse their work by expanding and collapsing thin elements which act as interactive pages of a book.</p>
        </ContentImageSplit>
        <SlickCarousel images={images} backgroundColor="rgba(255,255,255,0.025)" />
        <CenterContent subheading={"UX / UI - People"} heading={"Playfully Professional"}>
          <p>The work of Ross Barney Architects is highly polished and professional, but never loses sight of the principle fact that architecture is a humanistic pursuit: buildings serve the people that make use of them. In keeping with their values, I designed and developed their company staff listing to provide a chance for each team member to express their individuality.</p>
        </CenterContent>
        <CenterMedia placeholder={props.data.peopleImage.childImageSharp.fluid.src} videoSrc="https://player.vimeo.com/external/397026958.hd.mp4?s=8074eb3186f7eea46802ac92b9a33504383c9a97&profile_id=175" /* backgroundColor="#dddddd" */ />
        <AngledMockupsContent images={responsiveImages} subheading="responsive design & development" heading="conceptual<br/> consistency">
          <p>Despite the site having a uniquely experimental design, the experience on tablets and phones is unhindered, and manages to stay consistent with the digital catalog theme. Once a user navigates to a project page, the UI gets out of the way, making way for imagery that makes maximal use of screen real estate.</p>
        </AngledMockupsContent>
        <ProjectFooter image={props.data.footerImage.childImageSharp.fluid} />

      </animated.div>

    </>

  )
};

export default RossBarney;