import React, { useEffect, useContext } from "react";
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { useSpring, animated } from 'react-spring';
import testImg2 from '../images/soucie_horner.jpg';
import ProjectHeaderAlt from '../components/ProjectHeaderAlt';
import ContentImageSplit from '../components/ContentImageSplit';
import CenterContent from '../components/CenterContent';
import FooterCta from '../components/FooterCta'
import ProjectFooter from '../components/ProjectFooter';

export const projectData = {
  name: "Soucie Horner",
  client: "Soucie Horner",
  url: "/projects/soucie-horner",
  order: 7,
  excerpt: "Lorem Ipsum Excerpt goes here, keep it short",
  image: testImg2,
  listData: [
    {label: 'client', value:"Soucie Horner"},
    {label: 'agency', value:"Faust"},
    {label: 'services', value:"Web Development"},
    {label: 'role', value:"Full-stack Developer"},
    {label: 'technologies', value:"HTML, CSS, JS, PHP"},
  ],
  services: "Full-Stack Development",
  color: '#587479',
};
  
const SoucieHorner = props => {
  let pageMount = props.mount;

  const springProps = useSpring({
    opacity: pageMount ? 1 : 0,
    from: {
      opacity: 0
    }
  });

  const query = useStaticQuery(graphql`
    query soucieHornerImages {
      shIntro: file(name: {eq: "soucie_horner_design"}){
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      shHome: file(name: {eq: "sh_desktop_home"}){
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      shAbout: file(name: {eq: "sh_desktop_about"}){
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      shPortfolio: file(name: {eq: "sh_desktop_portfolio"}){
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      shPortfolioSingle: file(name: {eq: "sh_desktop_portfolio_single_edited"}){
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      shJournal: file(name: {eq: "sh_desktop_journal"}){
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      shJournalPost: file(name: {eq: "sh_desktop_journal_post"}){
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      footerImage: file(name: {eq: "sh_desktop_journal_post"}){
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      ogImage: file(name: {eq: "soucie_horner"}){
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      
    }
  `)

  return(
    <animated.div style={{opacity: springProps.opacity }} id="detail-container" className={"project-detail"}>
      <ProjectHeaderAlt location={props.location} ogImage={props.data.ogImage.childImageSharp.fluid.src} {...projectData} pageMount={pageMount}>
        <p>Soucie Horner, founded by Shea Soucie and Martin Horner, is a design firm specializing in luxury architectural interior design. Their new site, designed by Faust, seeks to present their 3 brands with an aesthetic that is fitting for the luxurious nature of their work. The site also acts as a hub for much of their social media efforts, which are tightly coupled with all of their marketing strategies. Utilizing WordPress as a CMS, the final product is a fully responsive site that allows their site to scale as quickly as they do.</p>
      </ProjectHeaderAlt>
      <ContentImageSplit centerAlign={false} skew="10deg" translateY="-30%" skewColor="rgba(255,255,255, 0.025)"  image={props.data.shHome.childImageSharp.fluid} heading={'Classy</br>& Clean'} subheading={'design'} >
        <p>The work of Soucie Horner, being visual in nature, is best described with pictures rather than words. Luckily; they've done an excellent job of documenting and photographing their work, which gave the designers at Faust plenty of source material to work with. The site's design, much like Soucie Horner's work, is classy and clean; taking advantage of high quality photographs complemented by solid typography.</p>
      </ContentImageSplit>

      <div className="container-fluid img-flow">
        <div className="row">
          <div className="col-md-8 mx-auto">
            <Img fluid={props.data.shAbout.childImageSharp.fluid} />
          </div>
          <div className="col-md-6 mr-auto">
            <Img fluid={props.data.shJournal.childImageSharp.fluid} />
          </div>
          <div className="content-wrap col-md-6">
            <div className="content ml-auto">
              <h4 className="subheading">UX / UI</h4>
              <h2>Editorial Elegance</h2>
              <p>The team at Soucie Horner frequently creates content providing behind-the-scenes insight to their projects, and design topics as well, further establishing them as an authority in their domain. To supplement their marketing efforts, we developed their journal to have a high-end editorial feel, giving an air of elegance that is in keeping with the aesthetic of their work.</p>
            </div>
          </div>
          <div className="col-md-9 mx-auto">
            <Img fluid={props.data.shJournalPost.childImageSharp.fluid} />
          </div>
        </div>
      </div>
      <CenterContent subheading={"UX / UI - Portfolio"} heading={"Picture Perfect"}>
        <p>In addition to acting as the vessel of their marketing activities, displaying their body of work is one of the site's most critical goals. Making use of a massive collection of professional photography, the portfolio was designed to place all attention on their work, and developed to make it easy for non-technical staff to continuously add new projects as needed.</p>
      </CenterContent>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-9 img-stack mx-auto">
            <Img fluid={props.data.shPortfolio.childImageSharp.fluid} />
            <Img fluid={props.data.shPortfolioSingle.childImageSharp.fluid} />
          </div>
        </div>
      </div>
      <FooterCta />
    </animated.div>


  )
};

export default SoucieHorner;