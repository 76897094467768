import React from 'react';
import testImg2 from '../images/bkb.jpg';

export const projectData = {
  name: "Portfolio V4",
  client: "Portfolio V4",
  url: "/projects/alex-al-hamdan-portfolio",
  order: 5,
  excerpt: "Video Portfolio.",
  image: testImg2,
  listData: [
    {label: 'services', value:"Full-Stack Development, Web Design"},
    {label: 'products', value:"Portfolio Site"},
    {label: 'technologies', value:"HTML, CSS, JS, PHP"},
  ],
  color: '#8dc63f',
};


const Alxalh = props => {
  return (
    <div>
      <h1>Portfolio V4</h1>
    </div>
  )
}

export default Alxalh;