import React from 'react';
import './MockupGrid.scss';

const MockupGrid =  props => {
  return(
    <div className="wrap__full mockup-grid" style={{background: "#8dc63f"}}>
      <div>
        <img src="https://picsum.photos/600/1200" alt=""/>
      </div>
      <div>
        <img src="https://picsum.photos/600/1200" alt=""/>
      </div>
      <div>
        <img src="https://picsum.photos/600/1200" alt=""/>
      </div>
      <div>
        <img src="https://picsum.photos/600/1200" alt=""/>
      </div>
      <div>
        <img src="https://picsum.photos/600/1200" alt=""/>
      </div>
      <div>
        <img src="https://picsum.photos/600/1200" alt=""/>
      </div>
      <div>
        <img src="https://picsum.photos/600/1200" alt=""/>
      </div>
      <div>
        <img src="https://picsum.photos/600/1200" alt=""/>
      </div>

    </div>
  )
}

export default MockupGrid;