module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-7145183-11","head":true,"pageTransitionDelay":2},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Alexander Al-Hamdan","short_name":"ALX ALH","start_url":"/","background_color":"#111111","theme_color":"#111111","display":"minimal-ui","icon":"src/images/alx_alh.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[],"layout":"C:\\Users\\Arccentric\\Documents\\000_Arccentric\\000_portfolio\\source_code\\alexalhamdan-dev-portfolio\\src\\components\\layout.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.alexalhamdan.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
