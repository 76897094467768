
import React, { useEffect, useContext } from "react";
import { graphql, useStaticQuery} from 'gatsby';
import Img from 'gatsby-image';
import { useSpring, animated } from 'react-spring';
import testImg2 from '../images/runaway.jpg';
import {ThreeContext} from '../three/ThreeContext';
import { Scrollbars } from 'react-custom-scrollbars';
import ContentImageSplit from '../components/ContentImageSplit';
import ProjectHeaderAlt from '../components/ProjectHeaderAlt';
import Masonry from 'react-masonry-component';
import CenterMedia from '../components/CenterMedia';
import CenterContent from '../components/CenterContent';
import AngledMockupsContent from '../components/AngledMockupsContent';
import SlickCarousel from '../components/SlickCarousel';
import ProjectFooter from '../components/ProjectFooter';
import vidPoster from '../images/project-content/runaway/placeholder.jpg';



export let projectData = {
  name: "Runaway Fitness",
  client: "Runaway Fitness",
  url: "/projects/runaway",
  order: 6,
  excerpt: "Lorem Ipsum Excerpt goes here, keep it short",
  image: testImg2,
  listData: [
    {label: 'client', value:"Runaway Fitness"},
    {label: 'agency', value:"Faust"},
    {label: 'services', value:"Web Development"},
    {label: 'role', value:"Full-Stack Developer"},
    {label: 'technologies', value:"HTML, CSS, JS, PHP"},
  ],
  services: "Full-Stack Development",
  color: '#111111',
};
  
const Runaway = props => {
  let pageMount = props.mount;

  const springProps = useSpring({
    opacity: pageMount ? 1 : 0,
    from: {
      opacity: 0
    }
  });

  const query = useStaticQuery(graphql`
    query runawayImages {
      home: file(name: {eq: "runaway_desktop_01_home"} ){
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      about: file(name: {eq: "runaway_desktop_02_about"} ){
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      classes: file(name: {eq: "runaway_desktop_04_classes"} ){
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      classHover: file(name: {eq: "runaway_desktop_05_classes"} ){
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      classDetail: file(name: {eq: "runaway_desktop_06_class_detail"} ){
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      footerImage: file(name: {eq: "soucie_horner"}){
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      ogImage: file(name: {eq: "runaway"}){
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      allFinal: allFile(filter: {relativeDirectory: {regex: "/runaway_desktop/"}}, , sort: {fields: name, order: ASC}) {
        edges {
          node {
            id
            childImageSharp {
              id
              fluid{
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      },
      schedule: allFile(filter: {relativeDirectory: {regex: "/runaway_schedule/"}}, , sort: {fields: name, order: ASC}) {
        edges {
          node {
            id
            childImageSharp {
              id
              fluid{
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      },
      responsiveImages: allFile(filter: {relativeDirectory: {regex: "/runaway_responsive/"}}, , sort: {fields: name, order: ASC}) {
        edges {
          node {
            id
            childImageSharp {
              id
              fluid{
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      },
  
    }
  `)


  let allFinal = props.data.allFinal.edges;
  let schedule = props.data.schedule.edges;
  let responsiveImages = props.data.responsiveImages.edges;

  let gridElements = allFinal.map( (image, i) => {
    // console.log(image.node.childImageSharp.fluid.src)
    let addClass = i === 5 ? '' : '';
    // let addClass = i === 5 ? 'double-wide' : '';
  
    return(
      <div className={`grid-item col-md-6 ${addClass}`} key={i}>
        <Img fluid={image.node.childImageSharp.fluid} />
      </div>
    )
  });

  gridElements.unshift(
    <div key={'i-content'} className="grid-item content-wrap col-md-6">
      <div className="content">
        <h4 className="subheading">Design</h4>
        <h2>Contrast<br/>& Content</h2>
        <p>Faust designed a site that was minimal yet bold, with a strong emphasis on typography and strong imagery. Unexpected user interactions elicit surprise, making for a user experience that is engaging, yet succinct in messaging.</p>
        
      </div>
    </div>
)
  
  



  return(
    <animated.div style={{opacity: springProps.opacity }} id="detail-container" className={"project-detail"}>
      <ProjectHeaderAlt location={props.location} ogImage={props.data.ogImage.childImageSharp.fluid.src} {...projectData} pageMount={pageMount}>
        <p>Runaway, a new immersive treadmill-focused training facility for runners, was in need of a marketing site that doubled as their e-commerce / scheduling portal. With the designers at Faust leading a full branding and design effort, I was called in to lead the technical direction and execution of their new site. Through the heavy use of javascript and CSS animations, the end result is a user experience that is as dynamic and kinetic as their training programs.</p>
      </ProjectHeaderAlt>
      <ContentImageSplit centerAlign={false} image={props.data.home.childImageSharp.fluid} heading={'Contrast</br>& Content'} subheading={'design'} >
        <p>Faust designed a site that was minimal yet bold, with a strong emphasis on typography and strong imagery. Unexpected user interactions elicit surprise, making for a user experience that is engaging, yet succinct in messaging.</p>
      </ContentImageSplit>
      {/* <MockupGrid /> */}
      <div className="container img-flow">
        <div className="row">
          <div className="col-md-8 mx-auto">
            <Img fluid={props.data.about.childImageSharp.fluid} />
          </div>
          <div className="col-md-8 mr-auto">
            <Img fluid={props.data.classDetail.childImageSharp.fluid} />
          </div>
          <div className="col-md-8 mx-auto">
            <Img fluid={props.data.classes.childImageSharp.fluid} />
          </div>
          <div className="col-md-8 ml-auto">
            <Img fluid={props.data.classHover.childImageSharp.fluid} />
          </div>
        </div>
      </div>
      <CenterContent subheading={"Ux / UI"} heading={"The Element of Surprise"}>
        <p>With the design having a very structured and spartan aesthetic, we sought opportunities to surprise the user in a way that breaks the mold. Simple interactions such as hover states result in animations with high contrast, and clean white backgrounds randomly become the backdrop for athletes running in and out of view.</p>
      </CenterContent>

      <CenterMedia placeholder={vidPoster} videoSrc="https://player.vimeo.com/external/420429659.hd.mp4?s=a342b7acfe79e22b3a604de58edcb3a38b8dec61&profile_id=175" /* backgroundColor="#dddddd" */ />

      <ContentImageSplit backgroundColor="rgba(255,255,255,0.025)" translateY="-10%" image={null} subheading="UX / UI - Scheduling" heading="When</br>& Where" imgHidden={true} >
        <p>By integrating Runaway's site with their MindBody POS, we were able to provide visitors with the ability to schedule classes while also selecting a specific workout area prior to stepping into the studio. Runaway's clientele can be sure to get their run on, even while on the run.</p>
      </ContentImageSplit>
      <SlickCarousel backgroundColor="rgba(255,255,255,0.025)" id='carousel-parent' images={schedule} />


      <AngledMockupsContent images={responsiveImages} subheading="responsive design & development" heading="Keep it Simple">
        <p>The simplicity of Faust's designs lends itself well to responsive development: Runaway's clientele can find information about the facility and classes, read articles about health and physical fitness, and book classes regardless of their device.</p>
      </AngledMockupsContent>
      

      <ProjectFooter image={props.data.footerImage.childImageSharp.fluid} />
    </animated.div>

  )
};

export default Runaway;


