import React, { useEffect, useContext } from "react";
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { animated, useSpring } from 'react-spring';
import testImg2 from '../images/learncore.jpg';
import {ThreeContext} from '../three/ThreeContext';
import {useTransitionState} from 'gatsby-plugin-transition-link/hooks';
import ProjectWrapper from '../components/ProjectWrapper';
import ProjectHeaderAlt from '../components/ProjectHeaderAlt';
import ContentImageSplit from '../components/ContentImageSplit';
import CenterContent from '../components/CenterContent';
import ProjectFooter from '../components/ProjectFooter';
import CenterMedia from '../components/CenterMedia';
import SlickCarousel from '../components/SlickCarousel';
import AngledMockupsContent from '../components/AngledMockupsContent';
import vidPoster from '../images/project-content/ross-barney-architects/desktop/places_screenshot__0000.jpg';



export const projectData = {
  name: "LearnCore",
  client: "LearnCore",
  url: "/projects/learncore",
  order: 0,
  excerpt: "Lorem Ipsum Excerpt goes here, keep it short",
  image: testImg2,
  listData: [
    {label: 'client', value:"LearnCore"},
    {label: 'services', value:"Full-Stack Development, UX & UI Design"},
    {label: 'role', value:"Front-End Lead, Design Director"},
    {label: 'products', value:"LMS Web Application, Marketing Site, iOS & Android App, SalesForce App"},
    {label: 'technologies', value:"HTML, CSS, Bootstrap, JS, Backbone.js, D3.js, Scala, PHP"},
  ],
  services: "Full-Stack Development, UX & UI Design",
  color: '#bf6bbd',
};

let transitionComplete = false;
  
const Learncore = (props) => {

  let pageMount = props.mount;


  const query = useStaticQuery(graphql`
    query learncoreImages {
      lcIntro: file(name: {eq: "lc_intro_image"}){
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      admin1: file(name: {eq: "lc_admin_cc1"}){
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      admin2: file(name: {eq: "lc_admin_cc9"}){
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      admin3: file(name: {eq: "lc_admin_reporting"}){
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      learnerImages: allFile(filter: {relativeDirectory: {regex: "/learncore/learner/"}}, , sort: {fields: name, order: ASC}) {
        edges {
          node {
            id
            childImageSharp {
              id
              fluid{
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      },
      appImages: allFile(filter: {relativeDirectory: {regex: "/learncore/app/"}}, , sort: {fields: name, order: ASC}) {
        edges {
          node {
            id
            childImageSharp {
              id
              fluid{
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      },
      marketingImage: file(name: {eq: "01_lc_marketing_home"}){
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      resourcesImage: file(name: {eq: "02_lc_resources"}){
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      caseImage: file(name: {eq: "03_lc_marketing_casestudy"}){
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      footerImage: file(name: {eq: "bkb"}){
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      ogImage: file(name: {eq: "learncore"}){
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      
    }
  `)

  const springProps = useSpring({
    opacity: pageMount ? 1 : 0,
    from: {
      opacity: 0
    }
  });

  return(
    
    <animated.div style={{opacity: springProps.opacity }} id="detail-container" className={"project-detail"}>
      <ProjectHeaderAlt location={props.location} ogImage={props.data.ogImage.childImageSharp.fluid.src} {...projectData} pageMount={pageMount}>
        <p>In 2012 I was approached by one of my regular clients, who had identified a major technological gap in the world of corporate training. While educational institutions had platforms like Blackboard (which in itself was already a monolithic legacy app), small and large businesses were still relying on antiquated onboarding procedures that failed to make use of modern tech. LearnCore was built as a solution to this problem.</p>
      </ProjectHeaderAlt>
      <ContentImageSplit altPadding={true} orderSwitch={true} skew="10deg" translateY="-40%" image={props.data.lcIntro.childImageSharp.fluid} skewColor="rgba(255,255,255, 0.025)"  subheading="Designer / Engineer / Jack of all trades" heading="From first<br />hire to $50M">
        <p>In the five years that followed, I worked with LearnCore to design and develop a SaaS learning management system where managers can create and upload content, administer courses to users, and test and certify comprehension. During that time, my role expanded as the company grew: I started as the sole designer / developer, and found myself in a hybrid role with the responsibilities of a marketing consultant, design director, front-end lead / manager, and product owner. LearnCore continued to grow and scale, and in June 2018 the company was acquired for $50M by ShowPad.</p>
      </ContentImageSplit>
      <CenterContent subheading={"Technology & UX"} heading={"Building the MVP"}>
        <p>Discussions of features and scope led to the decision to split the Admin and Learner UX into two separate applications. Once an MVP feature set was determined, I mapped out the site architecture, and visualized user flows. Working within the Play framework, the frontend was built using Bootstrap with custom CSS, Backbone.js for view templating, and jQuery for UI animations and interactions.</p>
      </CenterContent>
      <div className="container-fluid img-flow">
        <div className="row">
          <div className="col-md-6 mr-auto">
            <Img fluid={props.data.admin1.childImageSharp.fluid} />
          </div>
          <div className="content-wrap col-md-6">
            <div className="content ml-auto">
              <h4 className="subheading">UX / UI - Admin Application</h4>
              <h2>Course Creation<br />& Reporting</h2>
              <p>The Admin course creation experience was one of the most critical components of LearnCore. We avoided overwhelming admins by breaking the process into smaller and more modular steps: "Learn," for uploading and creating content, "Test," for creating automated tests, "Pitch IQ," a video role-playing tool, and "Groups," for user administration. The reporting module gives admins and managers the right tools to identify top performers and areas for improvement.</p>
            </div>
          </div>
          <div className="col-md-7 mx-auto">
            <Img fluid={props.data.admin2.childImageSharp.fluid} />
          </div>
          <div className="col-md-7 ml-auto">
            <Img fluid={props.data.admin3.childImageSharp.fluid} />
          </div>
        </div>
      </div>
      <ContentImageSplit backgroundColor="rgba(255,255,255,0.025)" translateY="-10%" image={null} subheading="UX / UI - Learner Application" heading="Bite-Sized Learning" imgHidden={true} >
        <p>The learner experience was designed and developed to be fun and easy to use, with the user dashboard being the starting point. Here, learners can get a quick view of their required courses, pick up where they left off, and review past performance. Once in a course, the UX / UI was designed to minimize distraction from learning materials. With the Pitch IQ module, learners also have a chance to review and rate their peer's recorded pitches. Through the use of competitive metrics and reporting, gamified features drove users to return to use the platform often to sharpen their skills.</p>
      </ContentImageSplit>
      <SlickCarousel backgroundColor="rgba(255,255,255,0.025)" id='carousel-parent' images={props.data.learnerImages.edges} />

      <CenterContent subheading={"UX / UI - Pitch IQ"} heading={"Peer-driven Pitch Improvement"}>
        <p>LearnCore's major market differentiator was Pitch IQ: a video recording tool that allowed users to record their sales pitches and have them be reviewed through the platform by their managers and / or peers. This facilitated knowledge-sharing within companies, helping to build teams that generate more revenue.</p>
      </CenterContent>
      <CenterMedia placeholder={vidPoster} videoSrc="https://player.vimeo.com/external/420863871.hd.mp4?s=fd8de5ae7a5c8df8a90814c68a33b85f94baa2df&profile_id=175"  />
      <AngledMockupsContent images={props.data.appImages.edges} subheading="iOS / Android application" heading="Learn at your own p(l)ace">
        <p>The LearnCore mobile app provided learners with all features of the web application, allowing users to study course content while commuting, or even review videos prior to pitching to real customers. Managers can also use the app to view reports and provide feedback on user pitches.</p>
      </AngledMockupsContent>
      <ContentImageSplit  skew="10deg" translateY="-40%" image={props.data.marketingImage.childImageSharp.fluid} skewColor="rgba(255,255,255, 0.025)"  subheading="Technology - Marketing" heading="Putting Data & Analytics to work">
        <p>LearnCore's marketing site was developed with 3 main goals: provide product information, generate leads, and establish LearnCore as an authority in the Sales Enablement industry. The site needed to be easy for non-technical personnel to use, so we used WordPress as a base CMS, supplemented with integrations from Salesforce,  MixPanel, Google Analytics, and Hubspot. This allowed us to split-test and optimize landing pages, and automate our Marketing campaigns and lead generation.</p>
      </ContentImageSplit>
      <div className="container-fluid img-flow">
        <div className="row">
          <div className="col-md-7 mx-auto">
            <Img fluid={props.data.resourcesImage.childImageSharp.fluid} />
          </div>
          <div className="col-md-7 mr-auto">
            <Img fluid={props.data.caseImage.childImageSharp.fluid} />
          </div>
          <div className="content-wrap col-md-4">
            <div className="content ml-auto">
              <h4 className="subheading">Marketing</h4>
              <h2>LearnCore Leadership</h2>
              <p>Through the use of Case Studies, White Papers, eBooks, and consistent generation of valuable content, the marketing site was critical in establishing LearnCore as an authority in the sales enablement industry.</p>
            </div>
          </div>
        </div>
      </div>

      <ProjectFooter image={props.data.footerImage.childImageSharp.fluid} />
    </animated.div>

  )
};

export default Learncore;