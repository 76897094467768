import { projectData as Learncore } from '../projects/learncore';
import { projectData as SoucieHorner } from '../projects/soucie-horner';
import { projectData as RossBarney } from '../projects/ross-barney-architects';
import { projectData as Runaway } from '../projects/runaway';
import { projectData as TeamMarketing } from '../projects/team-marketing-report';
import { projectData as Tempest } from '../projects/tempest';
import { projectData as Bkb } from '../projects/bkb-chicago';
import { projectData as PictureNorth } from '../projects/picture-north';
import { projectData as Alxalh } from '../projects/alxalh';


const projectList = [
  Learncore,
  Bkb,
  RossBarney,
  PictureNorth,
  TeamMarketing,
  Tempest,
  Runaway,
  SoucieHorner,
  // Alxalh,
];

projectList.forEach((project, i) => {
  project.order = i;
});

export default projectList;

