/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from 'react';
import {ThreeProvider} from './src/three/ThreeContext';
// export { default as wrapRootElement } from './src/store/ReduxWrapper';

export const wrapRootElement = ({element}) => (
  <ThreeProvider>{element}</ThreeProvider>
)

