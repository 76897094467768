import SceneManager from './SceneManager';

// function test(props){

//   return new Promise(resolve => {

//     // setTimeout(() => {
//     //  //console.log('timeout hit');
//     //   resolve('value')
//     // }, 2000);

//     async function testFunction(){
//      //console.log('level 2 start');

//       const value = await SceneManager();
//       resolve(value)

//     }
//     const anotherValue = testFunction();
//     return anotherValue
    

//   })




//   // return await entryPointTest(props);
// }


export default props => {

  return new Promise( resolve => {

    let sceneManager = {};

    async function compileEntryPoint(){

    
     //console.log('threeEntryStart...')
      let container = props.mount;
      const canvas = createCanvas(document, container);
     //console.log('sceneManager starting...', new Date());
      sceneManager = await new SceneManager(canvas);
     console.log(sceneManager);
     //console.log('line after scenemanager...', new Date());
      let canvasHalfWidth;
      let canvasHalfHeight;


      bindEventListeners();
      render();

      function createCanvas(document, container) {
        const canvas = document.createElement('canvas');
        container.appendChild(canvas);
        return canvas;
      }

      function bindEventListeners() {
       //console.log('bind event start');
        window.onresize = resizeCanvas;
        // window.onmousemove = mouseMove;
        
        resizeCanvas();
      }

      function resizeCanvas() {
        canvas.style.cssText = `
          z-index: -1;
          top: 0;
          left: 0;
          width: 100vw;
          height: calc(100 *  var(--vh));
        `;

        canvas.width  = canvas.offsetWidth;
        canvas.height = canvas.offsetHeight;

        window.canvas = canvas;

        canvasHalfWidth = Math.round(canvas.offsetWidth/2);
        canvasHalfHeight = Math.round(canvas.offsetHeight/2);
       //console.log('about to hit scenemanager window resize')
        sceneManager.onWindowResize()
      }

      // function mouseMove({screenX, screenY}) {
      //   // sceneManager.onMouseMove(screenX-canvasHalfWidth, screenY-canvasHalfHeight);
      //   sceneManager.onMouseMove(screenX, screenY);
      // }

      function render(time) {
        requestAnimationFrame(render);
        sceneManager.update({time});
      }
     //console.log('threeEntry end');
      resolve (
        {render, 
        sceneManager}
      )
    }
    compileEntryPoint();

  })


}