import React, { useEffect, useContext, useState } from "react";
import { useTransition, useSpring, animated, interpolate } from 'react-spring';
import {ThreeContext} from '../three/ThreeContext';
import TransitionLink from 'gatsby-plugin-transition-link';
import anime from 'animejs/lib/anime.es.js';
import BrowserDetection from 'react-browser-detection';
import BackIcon from '../svgs/keyboard-backspace.svg';
import NameLogo from './NameLogo';
import NameWrap from './NameWrap';
import Flag from '../svgs/flag_destructure.svg';

const Header = ({ siteTitle }) => {
  const { threeStarter,  pNum, toProjectFn, setTransition  } = useContext(ThreeContext);
  const [ visited, setVisited] = useState(false);
  const [ collapse, setCollapse] = useState('');
  const [ startGrid, setStartGrid ] = useState(false);
  const [ startStars, setStartStars ] = useState(false);

  useEffect(()=> {

    threeStarter && setIntroSprings({btnOpacity: 1});

  }, [threeStarter]);

  const transitions = useTransition( visited, null, {
    from: { opacity: 1, nameTY: 110, sumTY: -110 },
    enter: { opacity: 1, nameTY: 0, sumTY: 0 },
    leave: { opacity: 0, nameTY: 110, sumTY: -110 },
  })

  const [introSprings, setIntroSprings, stopIntroSprings] = useSpring(()=> ({opacity:0, nameTY:110, sumTY:-110, btnOpacity: 0}) ) ;

  function scrollTop(){
    
    let scrollDiv = document.getElementById('scroll-parent').children[0];
    
    anime({
      targets: scrollDiv,
      scrollTop: 0,
      // duration: 3000,
      easing: 'easeInOutExpo',
    })
  
  }

  const continueClick = () => {
    if(!threeStarter) return;
    setCollapse('transition');
    setIntroSprings({ sumTY: -110, btnOpacity: 0 })

    setTimeout(()=>{
      setVisited(true);
    }, 1000)
  }

  useEffect(() => {
    setStartGrid(true)
    setTimeout(()=> {
      setStartStars(true);
      setTimeout(()=>{
        setIntroSprings({ opacity: 1, nameTY: 0, sumTY: 0 })
      }, 1000 )
    }, 500)
  }, [])

  const browserHandler = {
    edge: () => '',
    // eslint-disable-next-line react/display-name
    default: () => <Flag className="destructure" />
  }

  return (
    <>
      <header>
        <div>
          <TransitionLink
              to='/'
              exit={{ 
                length: 1,
                trigger: ()=> {setTransition(true), scrollTop(), toProjectFn.toIndex(pNum) }
                }}
              entry={{
                delay: 1 ,
              }}
            >
              <NameLogo >Alexander Al-Hamdan</NameLogo>
          </TransitionLink>
        </div>
        <TransitionLink
          to='/'
          exit={{ 
            length: 1,
            trigger: ()=> {setTransition(true), scrollTop(), toProjectFn.toIndex(pNum) }
            }}
          entry={{
            delay: 1 ,
          }}
          className='to-projects'
        >
          <BackIcon/> All Projects
        </TransitionLink>
        

        <div className="nav">
          <TransitionLink
            to='/about'
            exit={{ 
            length: 1,
            trigger: ()=> {setTransition(true), scrollTop(), toProjectFn.updateShaders(pNum, 'profile') }
            }}
            entry={{
              delay: 1 ,
            }}
          >
            ABOUT
          </TransitionLink>
        </div>
      </header>

      {
        transitions.map(({item, key, props}, i) => {
          return !item  &&
          <animated.div style={{opacity: props.opacity}} key={key} className={"landing " + collapse}>
            <div className={ `grid-${startGrid} stars-${startStars}`} >
              {
                typeof window !== `undefined` &&
                <BrowserDetection>
                  { browserHandler }
                </BrowserDetection>
              }
            </div>
            <div className="landing-content">
              <div className="name-container">
                <animated.div style={{opacity: introSprings.opacity, transform: introSprings.nameTY.interpolate( y => `translateY(${y}%)`)}} className="name-container-animate">
                  <NameWrap collapse={collapse}>Alexander Al-Hamdan</NameWrap>
                </animated.div>
              </div>
              <div className="summary-container">
                <animated.p style={{opacity: introSprings.opacity, transform: introSprings.sumTY.interpolate( y => `translateY(${y}%)`)}}>Software Engineer / Designer & Creative Technologist</animated.p>
              </div>
              <div className="btn-container">
                <animated.div className={`btn-continue ${threeStarter && 'loaded'}`} onClick={continueClick} style={{opacity: introSprings.btnOpacity, transform: introSprings.sumTY.interpolate( y => `translateY(${y}%)`)}}>
                  <span>Continue</span>
                </animated.div>
              </div>
            </div>
          </animated.div>
          
        })
      }      

    </>

  )
}


export default Header
