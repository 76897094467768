/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title, ogImage, ogImageHeight, ogImageWidth, location }) {
  const { site, defaultOgImage } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        },
        defaultOgImage: file(name: {eq: "alx_alh_ogimage"}) {
          id
          childImageSharp {
            fluid{
              ...GatsbyImageSharpFluid
            }
          }
        }
  
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description;
  const ogImageUrl = ogImage !== undefined ? site.siteMetadata.siteUrl+ogImage : site.siteMetadata.siteUrl+defaultOgImage.childImageSharp.fluid.src;
  const ogImageHeightValue = ogImageHeight !== undefined ? ogImageHeight : 800;
  const ogImageWidthValue = ogImageHeight !== undefined ? ogImageWidth : 1200;
  const ogUrl = location !== undefined ? site.siteMetadata.siteUrl+location : site.siteMetadata.siteUrl ;
  // const ogUrl = site.siteMetadata.siteUrl || site.siteMetadata.siteUrl+location.pathname;

  return (
    <Helmet
      htmlAttributes={{
        lang,
        class: 'dark'
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: `${title} | ${site.siteMetadata.title}`,
        },
        {
          property: `og:image`,
          content: ogImageUrl,
        },
        {
          property: `og:image:width`,
          content: ogImageWidthValue,
        },
        {
          property: `og:image:height`,
          content: ogImageHeightValue,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:url`,
          content: ogUrl,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: `${title} | ${site.siteMetadata.title}`,
        },
        {
          name: `twitter:image`,
          content: ogImageUrl,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
    {/* <link href="https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap" rel='preload' as='font'></link> */}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
