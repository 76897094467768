import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { animated, useSpring } from 'react-spring';
import ProjectHeaderAlt from '../components/ProjectHeaderAlt';
import ProjectFooter from '../components/ProjectFooter';
import CenterMedia from '../components/CenterMedia';
import CenterContent from '../components/CenterContent';
import ContentImageSplit from '../components/ContentImageSplit';
import vidPoster from '../images/project-content/picture-north/placeholder.jpg';
import SlickCarousel from '../components/SlickCarousel';
import testImg2 from '../images/picture_north.jpg';
import AngledMockupsContent from '../components/AngledMockupsContent';



export const projectData = {
  name: "Picture North",
  client: "Picture North",
  url: "/projects/picture-north",
  order: 3,
  excerpt: "Video Portfolio.",
  image: testImg2,
  listData: [
    {label: 'client', value:"Picture North"},
    {label: 'services', value:"Full-Stack Development, Web Design"},
    {label: 'products', value:"Portfolio Site"},
    {label: 'technologies', value:"HTML, CSS, JS, Gatsby.js, React.js, GraphQL"},
  ],
  services: "Full-Stack Development, UX & UI Design",
  color: '#83a9b2',
};




const PictureNorth = props => {
  let pageMount = props.mount;


  const query = useStaticQuery(graphql`
    query pictureNorthImages {
      pnIntro: file(name: {eq: "picture_north_design_2"}){
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      pnDesktop: allFile(filter: {relativeDirectory: {regex: "/picture-north/desktop/"}}, sort: {fields: name, order: ASC}) {
        edges {
          node {
            id
            childImageSharp {
              id
              fluid (maxWidth:2000){
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      },
      pnResponsive: allFile(filter: {relativeDirectory: {regex: "/picture-north/responsive/"}}, sort: {fields: name, order: ASC}) {
        edges {
          node {
            id
            childImageSharp {
              id
              fluid(maxWidth: 752, maxHeight: 1624){
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      },

      footerImage: file(name: {eq: "teammarketing"}){
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      ogImage: file(name: {eq: "picture_north"}){
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      
    }
  `)

  const springProps = useSpring({
    opacity: pageMount ? 1 : 0,
    from: {
      opacity: 0
    }
  });

  

  return (
    <animated.div style={{opacity: springProps.opacity }} id="detail-container" className={"project-detail"}>
      <ProjectHeaderAlt location={props.location} ogImage={props.data.ogImage.childImageSharp.fluid.src} {...projectData} pageMount={pageMount}>
        <p>Picture North is a production studio that works with advertising agencies, record labels, and direct collaborators to produce Hollywood-quality video content. Picture North needed a singular digital home to showcase their work and supplement their agency pitches. I was tasked with the refinement of their preliminary designs and the technical development and execution of their new portfolio site.</p>
        <a target="_blank" rel="noopener noreferrer" href="http://picturenorth.com">Visit Site</a>
      </ProjectHeaderAlt>
      <ContentImageSplit orderSwitch={true} col1Class='col-md-4' col2Class='col-md-8' centerAlign={true} /* backgroundColor="rgba(255,255,255, 0.1)" */ skew="-10deg" translateY="-23%" image={props.data.pnIntro.childImageSharp.fluid} skewColor="rgba(255,255,255, 0.025)"  subheading="design inspiration" heading="Frame<br/>By Frame">
        <p>With every frame of every video being a work of art in itself, the design for the site shines a spotlight on Picture North's videos and directorial talent.</p>
      </ContentImageSplit>

      <CenterContent subheading={"solution"} heading={"Speed & Simplicity"}>
        <p>In addition to showcasing Picture North's video content, speed in both technical performance and UX was a primary goal. Through the use of the Gatsby.js framework, the site loads incredibly fast, with cutting-edge features and techniques such as progressive image enhancement, and predictive content pre-loading.</p>
      </CenterContent>
      <CenterMedia placeholder={vidPoster} videoSrc="https://player.vimeo.com/external/420434993.hd.mp4?s=ab55f344918b9337713fe47a60e933b7eb1db4e2&profile_id=175" /* backgroundColor="#dddddd" */ />


      <ContentImageSplit backgroundColor="rgba(255,255,255,0.025)" translateY="-10%" image={null} subheading="UX / UI" heading="Taking in the Scenery" imgHidden={true} >
        <p>Visitors are immediately greeted with a jaw-dropping video compilation of some of Picture North's landscape shots. To supplement the speed provided by React and GraphQL, users can be watching videos in as few as two clicks. The UX / UI is there only when you need it, and is quick to make space for content.</p>
      </ContentImageSplit>
      <SlickCarousel images={props.data.pnDesktop.edges} backgroundColor="rgba(255,255,255,0.025)" />

      {/* <AngledMockupsContent images={props.data.pnResponsive.edges} subheading="responsive design & development" heading="conceptual<br/> consistency">
        <p>Before the internet grew to be more accessible, architects traditionally showcased their work in large architectural catalogs. It is extremely common to find that architectural firms often have their own libraries filled with tomes; not just for displaying their own work, but for seeking inspiration in the work of others.</p>
      </AngledMockupsContent> */}


      <ProjectFooter image={props.data.footerImage.childImageSharp.fluid} />
    </animated.div>

  )
}

export default PictureNorth;