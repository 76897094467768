import React, { useEffect, useContext } from "react";
import { graphql, useStaticQuery} from 'gatsby';
import Img from 'gatsby-image';
import testImg2 from '../images/bkb.jpg';
import {ThreeContext} from '../three/ThreeContext';
import { useSpring, animated } from 'react-spring';
import ProjectHeaderAlt from '../components/ProjectHeaderAlt';
import ContentImageSplit from '../components/ContentImageSplit';
import HoverImageSplit from '../components/HoverImageSplit';
import CenterContent from '../components/CenterContent';
import SidebarContent from '../components/SidebarContent';
import CenterMedia from '../components/CenterMedia';
import MasonryGallery from '../components/MasonryGallery';
import Masonry from 'react-masonry-component';
import MediaCarousel from '../components/MediaCarousel';
import SlickCarousel from '../components/SlickCarousel';
import AngledMockupsContent from '../components/AngledMockupsContent';
import ProjectFooter from '../components/ProjectFooter';
import VisibilitySensor from "react-visibility-sensor";
import vidPoster from '../images/project-content/ross-barney-architects/desktop/places_screenshot__0000.jpg';




export const projectData = {
  name: "With Flying Colors",
  client: "Brooklyn Boulders Chicago",
  url: "/projects/bkb-chicago",
  order: 1,
  excerpt: "Lorem Ipsum Excerpt goes here, keep it short",
  image: testImg2,
  listData: [
    {label: 'client', value:"Brooklyn Boulders Chicago"},
    {label: 'services', value:"Generative Design, Architectural Installation, Digital Fabrication"},
    {label: 'role', value:"Designer, Developer, Fabricator"},
    {label: 'technologies', value:"Python, RhinoScript"},
    {label: 'in collaboration with', value:"Hannah Ellen Art"},
    {label: 'photography by', value:"Michael Nowicki"},
  ],
  services: "Generative Design, Architectural Installation, Digital Fabrication",
  color: '#52cbff',
};
  
const Bkb = props => {
  let pageMount = props.mount;

  const springProps = useSpring({
    opacity: pageMount ? 1 : 0,
    from: {
      opacity: 0
    }
  });

  const query = useStaticQuery(graphql`
  query bkbImages {
    buildImages: allFile(filter: {relativeDirectory: {regex: "/bkb_build_progress/"}}, sort: {fields: name, order: ASC}) {
      edges {
        node {
          id
          name
          childImageSharp {
            id
            fluid (maxWidth:2000){
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    },
    buildImagesFixed: allFile(filter: {relativeDirectory: {regex: "/bkb_build_progress/"}}, sort: {fields: name, order: ASC}) {
      edges {
        node {
          id
          name
          childImageSharp {
            id
            fixed (height:800){
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    },
    buildImagesFixedMobile: allFile(filter: {relativeDirectory: {regex: "/bkb_build_progress/"}}, sort: {fields: name, order: ASC}) {
      edges {
        node {
          id
          name
          childImageSharp {
            id
            fixed (height:400){
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    },
    responsiveScreens: allFile(filter: {name: {regex: "/respons/"}}) {
      edges {
        node {
          id
          childImageSharp {
            id
            fluid(maxWidth: 752, maxHeight: 1624){
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    },
    allFinal: allFile(filter: {relativeDirectory: {regex: "/final_build/"}}, , sort: {fields: name, order: ASC}) {
      edges {
        node {
          id
          childImageSharp {
            id
            fluid{
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    },
    mainRoomImage: file(name: {eq: "main_room_poly"}) {
      id
      childImageSharp {
        fluid{
          ...GatsbyImageSharpFluid
        }
      }
    },
    ogImage: file(name: {eq: "bkb"}) {
      id
      childImageSharp {
        fluid{
          ...GatsbyImageSharpFluid
        }
      }
    },
    flagImage: file(name: {eq: "chicago_flag"}) {
      id
      childImageSharp {
        fluid{
          ...GatsbyImageSharpFluid
        }
      }
    }
    flagSketch: file(name: {eq: "flag_destructure"}) {
      id
      childImageSharp {
        fluid{
          ...GatsbyImageSharpFluid
        }
      }
    },
    final01: file(name: {eq: "BKB_Stars_01"}) {
      id
      childImageSharp {
        fluid{
          ...GatsbyImageSharpFluid
        }
      }
    },
    final02: file(name: {eq: "BKB_Stars_02"}) {
      id
      childImageSharp {
        fluid{
          ...GatsbyImageSharpFluid
        }
      }
    },
    final03: file(name: {eq: "BKB_Stars_03"}) {
      id
      childImageSharp {
        fluid{
          ...GatsbyImageSharpFluid
        }
      }
    },
    final04: file(name: {eq: "BKB_Stars_04"}) {
      id
      childImageSharp {
        fluid{
          ...GatsbyImageSharpFluid
        }
      }
    },
    final05: file(name: {eq: "BKB_Stars_05"}) {
      id
      childImageSharp {
        fluid{
          ...GatsbyImageSharpFluid
        }
      }
    },
    final06: file(name: {eq: "BKB_Stars_06"}) {
      id
      childImageSharp {
        fluid{
          ...GatsbyImageSharpFluid
        }
      }
    },
    final07: file(name: {eq: "BKB_Stars_07"}) {
      id
      childImageSharp {
        fluid{
          ...GatsbyImageSharpFluid
        }
      }
    },
    final08: file(name: {eq: "BKB_Stars_08"}) {
      id
      childImageSharp {
        fluid{
          ...GatsbyImageSharpFluid
        }
      }
    },
    final09: file(name: {eq: "BKB_Stars_09"}) {
      id
      childImageSharp {
        fluid{
          ...GatsbyImageSharpFluid
        }
      }
    },
    footerImage: file(name: {eq: "r_barc"}) {
      id
      childImageSharp {
        fluid{
          ...GatsbyImageSharpFluid
        }
      }
    },
  }
`)

let images = props.data.buildImages.edges;
let allFinal = props.data.allFinal.edges;
let imagesFixed = props.data.buildImagesFixed.edges;
let responsiveImages = props.data.responsiveScreens.edges;

let photos = allFinal.map( image => {
  let t;
  return {
    src: image.node.childImageSharp.fluid.src,
    width: 4,
    height: 3
  
  };
})

let gridElements = allFinal.map( (image, i) => {
  // console.log(image.node.childImageSharp.fluid.src)
  let addClass = i === 5 ? 'double-wide' : '';

  return(
    <div className={`grid-item ${addClass}`} key={i}>
      <Img fluid={image.node.childImageSharp.fluid} />
    </div>
  )
})


  return(
    <animated.div style={{opacity: springProps.opacity }} id="detail-container" className={"project-detail"}>
      <ProjectHeaderAlt location={props.location} ogImage={props.data.ogImage.childImageSharp.fluid.src} {...projectData} pageMount={pageMount}>
        <p>Brooklyn Boulders recently renovated the training facilities of their Chicago location, and asked me to create a large-scale permanent art piece for the space. In collaboration with painter / muralist Hannah Ellen, we created a 3-dimensional art piece that transforms the space, unrestricted by the wall it occupies.</p>
        <p>Special Thanks to the following people for help making this project a reality: Claire Bao, Eric Sipos, Panos Fiorentinos, Alex Adams, Thomas Li, Camilla & Alfredo Graniello, Joe Welch, Chris Noth, Rob Anderson, Emma Husar, Michael Nowicki, Kasia Kois, and Paula Juchas.</p>
      </ProjectHeaderAlt>

      {/* <MediaCarousel dynamicHeight={true} images={images} backgroundColor="rgba(255,255,255,0.025)" /> */}

      <ContentImageSplit altPadding={true} orderSwitch={true} /* backgroundColor="rgba(255,255,255, 0.1)" */ skew="10deg" translateY="-40%" image={props.data.mainRoomImage.childImageSharp.fluid} skewColor="rgba(255,255,255, 0.025)" centerAlign={true}  subheading="design inspiration" heading="Climbing<br />Community">
        <p>Though the company originates in Brooklyn, the success of the gym is largely attributed to the strength of its local community of climbers. I chose to represent this by developing a creative concept that sought to act as an abstract interpretation of the iconic Chicago Flag.</p>
      </ContentImageSplit>
      <HoverImageSplit image={props.data.flagImage.childImageSharp.fluid} hoverImage={props.data.flagSketch.childImageSharp.fluid} heading="Deconstructing<br/>an Icon" subheading="design concept" >
        <p>Ranked 2nd best flag by the North American Vexillological Society, the Chicago flag sets a high standard for its excellent design, every element of which carries historical significance. I started my creative exploration by deconstructing the flag into intersecting lines; examining the density, spatial relationships, and voids created in the process. This lead to a concept focused on the generation of the stars form through a layering of overlapping geometry.</p>
      </HoverImageSplit>
      <CenterContent subheading={"design process"} heading={"Points, Geometry, Form"}>
        <p>Utilizing the programming interface provided by Rhinoceros 3d, I developed a parametric / generative design that relied on a combination of Python, Rhinoscript, and built-in components. Through the use of code, cartesian coordinates identify points, points form the basis of geometry, and geometry makes way for form.</p>
      </CenterContent>
      <CenterMedia placeholder={vidPoster} videoSrc="https://player.vimeo.com/external/421263058.hd.mp4?s=bd95f8e97e53b3ef1dda504f30c8900f755f5cf7&profile_id=174" /* backgroundColor="#dddddd" */ />
      {/* <MasonryGallery /> */}
      <ContentImageSplit backgroundColor="rgba(255,255,255,0.025)" translateY="-10%" image={null} subheading="Fabrication & Build" heading="" imgHidden={true} >
        <p>Once the design was finalized, all parts were fabricated from thick-stock bristol board, using high precision laser cutters. All pieces were then painted using high-grade spray paint, resulting in an almost metallic finish. With the help of the community at Brooklyn Boulders, each star was then assembled using a combination of interlocking notches, adhesives, and fishing line.</p>
      </ContentImageSplit>
      <SlickCarousel backgroundColor="rgba(255,255,255,0.025)" id='carousel-parent' images={images} />

      {/* <MediaCarousel images={images} backgroundColor="rgba(255,255,255,0.025)" /> */}
      <CenterContent subheading={"Finished artwork"} heading={"With Flying Colors"}>
        <p>The culmination of hundreds of hours of design, programming, fabrication, and assembly, the finished piece serves as a soaring focal point of the space it occupies. Hannah Ellen's abstract mural draws your gaze towards the center of the piece, framing the stars in freeform space. As you shift your gaze upward, each star progressively defies gravity by floating off the wall despite increasing in size and weight.</p>
      </CenterContent>

      <div className="container img-flow">
        <div className="row">
          <div className="col-md-8 mx-auto">
            <Img fluid={props.data.final01.childImageSharp.fluid} />
          </div>
          <div className="col-md-9 ml-auto">
            <Img fluid={props.data.final02.childImageSharp.fluid} />
          </div>
          <div className="col-md-9 mr-auto">
            <Img fluid={props.data.final03.childImageSharp.fluid} />
          </div>
          <div className="col-md-9 mx-auto">
            <Img fluid={props.data.final04.childImageSharp.fluid} />
          </div>
          <div className="col-md-8 mx-auto">
            <Img fluid={props.data.final05.childImageSharp.fluid} />
          </div>
          <div className="col-md-9 ml-auto">
            <Img fluid={props.data.final06.childImageSharp.fluid} />
          </div>
          <div className="col-md-9 mr-auto">
            <Img fluid={props.data.final07.childImageSharp.fluid} />
          </div>
          <div className="col-md-9 mx-auto">
            <Img fluid={props.data.final08.childImageSharp.fluid} />
          </div>
          <div className="col-md-9 mx-auto">
            <Img fluid={props.data.final09.childImageSharp.fluid} />
          </div>
        </div>
      </div>
      
      {/* <div className="container-fluid">
          <Masonry
            className={'grid-gallery'}
          >
            {gridElements}
          </Masonry>

      </div> */}
      <ProjectFooter image={props.data.footerImage.childImageSharp.fluid} />

    </animated.div>

  )
};

export default Bkb;