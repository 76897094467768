import React from 'react';
import './CenterMedia.scss';


const vidReadyHandler = (e) => {
  let vid = document.getElementById('vid-bg')

  if (vid.readyState >= vid.HAVE_FUTURE_DATA) {
    vid.style.opacity = 1
  
  } else {
      vid.addEventListener('canplay', function () {
        vid.style.opacity = 1
      
      }, false);
  }
  
  vid.style.opacity = 1
}

const CenterMedia = props => {
  const { backgroundColor, placeholder, videoSrc} = props;
  return(
    <div style={{backgroundColor}} className="wrap__full">
      <div className="container">
        <video onCanPlay={(e) => vidReadyHandler()} poster={placeholder} className='vid-bg' id='vid-bg' autoPlay muted loop controls style={{backgroundImage:  `url(${placeholder})`}} data-wf-ignore="true"><source src={videoSrc} data-wf-ignore="true" /></video>
      </div>
    </div>
  )
}
export default CenterMedia;