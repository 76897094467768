import React from 'react';
import './CenterContent.scss';

 const CenterContent = (props) => {
  const {subheading, heading} = props;
  // console.log(props)
  // console.log(props.children);
  return(
    <div className="wrap__full">
      <div className="container-fluid">
        <div className="center-content">
          <h4 className="subheading">{subheading}</h4>
          <h2>{heading}</h2>
          {props.children}
        </div>
      </div>
    </div>
  )
}
export default CenterContent;