import React from 'react';
import Img from 'gatsby-image';
import './AngledMockupsContent.scss'

const AngledMockupsContent = props => {
  const {subheading, heading, children, images} = props;
  return(
    <div className="wrap__full">
      <div className="container-fluid">
        <div className="angled-mockups ptb-xl">
          <div className="angled-mockups-content">
            { subheading && <h4 className="subheading">{subheading}</h4>}
            { heading && <h2 dangerouslySetInnerHTML={{__html: heading}} />}
            { children}
          </div>
          {images.map( (image, i) => (
            <Img key={`amc-${i}`} imgStyle={{objectFit: 'contain', height: 'auto', paddingBottom: 0}} className={`img-${i+1}`} fluid={image.node.childImageSharp.fluid} />
          ))}
        </div>
      </div>
    </div>
  )
}
export default AngledMockupsContent;