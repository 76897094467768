import React, { useEffect, useContext, useState} from 'react';
import { useSpring, useTransition, useTrail, animated } from 'react-spring';
import {ThreeContext} from '../three/ThreeContext';
import ChevronDown from  '../svgs/chevron-thin-down.svg';
// import { GatsbySeo } from 'gatsby-plugin-next-seo';
import SEO from '../components/seo';
import TransitionLink from 'gatsby-plugin-transition-link';
import anime from 'animejs/lib/anime.es.js';
import projectList from './projectList';

import './ProjectHeaderAlt.scss';

const ProjectHeaderAlt = props => {
  let { children, pageMount, ogImage} = props;
  
  const { name, order, listData } = props;
  const { setPnum, toProjectFn, threeStarter, setAngleTicks, setInProject, setTransition, fromProject, setFromProject} = useContext(ThreeContext);
  const [ headerClass, setHeaderClass ] = useState('');

  useEffect(()=>{
    //set scrolltop to 0 on first render - fixes browser button issues
    setTransition(true)
    document.getElementById('scroll-parent').children[0].scrollTop = 0;

    setPnum(order);
    setAngleTicks(order);
    let nextProjectNum ;
    let currentProject;
    // console.log('plist', projectList);
    // if(projectList.length === order + 1){
    //   currentProject = projectList.length;
    //   nextProjectNum = 0;
    // } else {
    //   currentProject = order ;
    //   nextProjectNum = order + 1;

    // }
    // fromProject === false ? toProjectFn.transitionToProject(order, true) : toProjectFn.toNextProject(currentProject, nextProjectNum);
    // threeStarter && toProjectFn  && fromProject === false ? toProjectFn.transitionToProject(order, true) : toProjectFn.toNextProject(order-1, order);
    setTimeout(() => {
      if(document.getElementById('scroll-parent').children[0].scrollTop == 0){
        setHeaderClass('nudge');

      }

    }, 3000)

  },[])
  
  useEffect(()=>{
    console.log(toProjectFn);
    if(toProjectFn !== undefined){
      fromProject === false ? toProjectFn.transitionToProject(order, true) : toProjectFn.toNextProject(order-1, order);
      setInProject(true);
      setTransition(false);
      setFromProject(false);
        
    }
  }, [threeStarter])

  const description = children.length > 1 ? children[0].props.children.slice(0, 150).toString() : children.props.children.slice(0, 150).toString();
  
  return (
    <>

      <SEO title={name} location={props.location.pathname} ogImage={ogImage} ogImageHeight='1125' ogImageWidth='2000' description={`${description}...`} />
      <div className="project-header-wrap">
        <div className={`project-header ${headerClass}`}>
          <div className="container-fluid  project-intro">
            <h1 className="bebas">{name}</h1>
            <span className="project-number">{(order+1).toString().padStart(2,0)}</span>
          </div>
        </div>
        <div className="full__wrap p-info-wrap">
          <div className="container p-info">
            <div className="project-info row">
              <div className="info-wrap col-md-6">
                {
                  listData.map( (item , index) => (
                    <div key={`key-${index}`} className="info-block">
                      <h5 className="info-label">{item.label}</h5>
                      <h4 className="info-data">{item.value}</h4>
                    </div>
                  ))
                }
              </div>
              <div className="brief-wrap col-md-6">
                <h5 className="info-label">Project Brief</h5>
                {children}
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </>
  )
}

export default ProjectHeaderAlt;