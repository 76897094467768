import React from 'react';
import Img from 'gatsby-image'
import './ContentImageSplit.scss';

const ContentImageSplit =  props => {
  const {subheading, heading, children, backgroundColor, skew, skewColor, image, translateY, imgHidden, centerAlign, orderSwitch } = props;
  let col1Class = props.col1Class !== undefined ? props.col1Class : 'col-lg-6'; 
  let col2Class = props.col2Class !== undefined ? props.col2Class : 'col-lg-6';
  let col1Order = orderSwitch ? 'order-1 order-lg-0' : '';
  let centerClass = centerAlign ? 'center-align' : '';
  let paddingStyle = imgHidden ? 'alt-padding' : '';
  return(
    <div style={{backgroundColor}} className={`wrap__full content-image-split ${paddingStyle}`}>
      { skew && <div style={{transform: `skewY(${skew}) translateY(${translateY})`, backgroundColor: skewColor}} className="bg-skew"></div> }
      <div className="container-fluid ptb-xl">
        <div className={`content-split ${centerClass} row`}>
          <div className={`content-wrap ${col1Order} ${col1Class}`}>
            <div className="content">
              { subheading && <h4 className="subheading">{subheading}</h4>}
              { heading && <h2 dangerouslySetInnerHTML={{__html: heading}} />}
              { children}
              
            </div>
          </div>
          {
            image &&
            <div className={`image-wrap ${col2Class}`}>
              <Img loading='eager' imgStyle={{objectFit: 'contain'}} fluid={image} alt={heading}/>
            </div>
          }
        </div>
      </div>
    </div>
  )
}
export default ContentImageSplit;