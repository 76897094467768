import React from "react";
import './TwoColumnText.scss';


export default props => {
  return (
    <div className="wrap__full">
      <div className="brief">
        <div>
          <h2>Client Brief</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse placerat in ligula a dapibus. Ut iaculis pretium tincidunt. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Quisque id lorem felis. Nullam facilisis malesuada lacus pretium vulputate. Mauris id mi at felis fringilla rutrum cursus eu lectus. Integer at risus lacinia, varius ante eget, luctus lectus.</p>
        </div>
        <div>
          <h2>Solution</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse placerat in ligula a dapibus. Ut iaculis pretium tincidunt. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Quisque id lorem felis. Nullam facilisis malesuada lacus pretium vulputate. Mauris id mi at felis fringilla rutrum cursus eu lectus. Integer at risus lacinia, varius ante eget, luctus lectus.</p>
        </div>
      </div>
    </div>
  )
}