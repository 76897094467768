import React, { useContext, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import Img from 'gatsby-image';
import TransitionLink from 'gatsby-plugin-transition-link';
import projectList from './projectList';
import { ThreeContext } from '../three/ThreeContext';
import anime from 'animejs/lib/anime.es.js';
import FooterCta from './FooterCta';
import VisibilitySensor from "react-visibility-sensor";
import './ProjectFooter.scss';




const ProjectFooter = props => {
  const {toProjectFn, rotateGroup, pNum, setPnum, theta, angleTicks, setTransition, fromProject, setFromProject} = useContext(ThreeContext);
  const image = props.image;
  let [footerVisible, setFooterVisible] = useState(false);

  function nextProjectOld(){
    setTransition(true)
    let rotgrp = rotateGroup.group.rotation;
    let count = angleTicks+1;
    console.log('pnum', pNum, pNum+1);
    toProjectFn.toNextProject(pNum, pNum+1, true);

    anime({
      targets: rotgrp,
      z: -theta * count,
      easing: 'easeInOutExpo',
      update: () => {
        // console.log('rz', rotgrp.z);
        // rotationObject.current += rotationObject.angle
        // rotateGroup.rotateGroup( rotationObject.angle);
      },
      begin: ()=> {
        // console.log(rotationObject.angle);
      },
      complete: () => {
        // setTransition(false);
        // toProjectFn.updateList(delta);

        // toProjectFn.updateShaders(pNum);
        setPnum(pNum+1);

      }
    })

    
  }

  function nextProject(){
    
    let scrollDiv = document.getElementById('scroll-parent').children[0];
    
    anime({
      targets: scrollDiv,
      scrollTop: 0,
      // duration: 3000,
      easing: 'easeInOutExpo',
    })
  
  }


  function test(isVisible){
    // if(isVisible){
    //   setFooterVisible(true);
    // } else {
    //   setFooterVisible(false);
    // }

    console.log(isVisible )

    // console.log('nps', initializedFooter);
    // if(!initializedFooter){
    //   initializedFooter = true;
    //   // canvasChanges++;
    //   return; 
    // };
    // console.log(isVisible, pNum);
    if(pNum === undefined) return;
    // window.nps(pNum, pNum+1, isVisible);
    // canvasChanges++;
  }

  let nextUrl;
  let nextProjectName;

  if(typeof window != 'undefined'){
    if(projectList.length === pNum + 1){
      nextUrl = projectList[0].url;
      nextProjectName = projectList[0].name;
    } else {
      nextUrl = projectList[pNum+1].url;
      nextProjectName = projectList[pNum+1].name;
    }

  }



  


  return(
    <div className="wrap__full footer">
      <FooterCta />
      <div className="project-footer">
        <TransitionLink
          to={nextUrl }
          exit={{
            length: 1,
            // delay:1,
            trigger: ()=> { nextProject(), setFooterVisible(true), setFromProject(true) /*, toProjectFn.nextProjectClick(pNum, pNum+1) /* , nextProject() */ }
          }}
          entry={{
            delay: 1,
            length:1,
            // state:{
            //   fromProject: true,
            // }
          }}
          
        >
          <div className="next-project ptb-xl">
            <Img style={{position: 'absolute'}} fluid={image} />
            <h4 className="subheading">Next Project</h4>
            <h2>{nextProjectName}</h2>
          </div>

        </TransitionLink>
      </div>

    </div>
  )
}
export default ProjectFooter;