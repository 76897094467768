import React from 'react';
import Img from 'gatsby-image';
import './HoverImageSplit.scss';


const HoverImageSplit = props => {

  const {image, hoverImage, heading, subheading, children} = props;

  return (
    <div className="wrap__full hover-image-split">
      <div className="container-fluid p-0">
        <div className="row">
          <div className="col-md-6 p-0">
            <Img fluid={image} />
            <div className="hover-wrap">
              <Img className="hover-reveal" fluid={hoverImage} />
            </div>
          </div>
          <div className="col-md-6 col-content">
            <div className="content-wrap">
              <div className="content">
                { subheading && <h4 className="subheading">{subheading}</h4>}
                { heading && <h2 dangerouslySetInnerHTML={{__html: heading}} />}
                { children}
                
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default HoverImageSplit;