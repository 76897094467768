import React, { useState, useEffect } from 'react';
import threeEntryPoint from '../three/threeEntryPoint';

const initialState = {};
export const ThreeContext = React.createContext(initialState);

export const ThreeProvider = props => {
  const [pNum, setPnum] = useState(0);
  const [transition, setTransition] = useState(false);
  const [toProjectFn, setToProjectFn] = useState();
  const [rotateGroup, setRotateGroup] = useState();
  const [theta, setTheta] = useState();
  const [angleTicks, setAngleTicks] = useState(undefined);
  const [threeStarter, setThreeStarter] = useState(false);
  const [inProject, setInProject] = useState(false);
  const [fromProject, setFromProject] = useState(false);
  const [canvasRef, setCanvasRef] = useState();
  const [projectList, setProjectList] = useState();
  let mount;
  // let threeEntry;
  

  
  // useEffect(()=>{
  //   // const threeEntry = threeEntryPoint({mount, pNum});

  //   async function startEntry(){
  //     const test = await threeEntryPoint({mount, pNum});
      
  //     console.log(test);
  //     console.log('threeEntry finished');
  //     setThreeStarter(true);

  //     return test
  //   }
  

  //   startEntry()

  // }, [])


  return (
    <ThreeContext.Provider value={{pNum, setPnum, transition, setTransition, toProjectFn, setToProjectFn, threeStarter, setThreeStarter, inProject, setInProject, rotateGroup, setRotateGroup, theta, setTheta, setCanvasRef, canvasRef, angleTicks, setAngleTicks, projectList, setProjectList, fromProject, setFromProject }}>
      { props.children}
      {/* <div id="canvas-wrap" ref={ node => mount = node }></div> */}
    </ThreeContext.Provider>
  )
}