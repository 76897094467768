import React, { useState } from 'react';
import { useSpring, animated, useTransition } from 'react-spring';
import './NameWrap.scss';

const LogoMarkup = ({name, keepers}) => {

  return(
    <>
      <div className="name-split logo" >

        {
          name.map( (letter, i) => {
            let classStr = 'ltr';
            if( keepers.includes(i) ){
              if(i === 9){
                classStr = 'space';
              } else {
                classStr = 'ltr-keep';
              }

            }
            return (
              <span className={classStr} key={`logo-${i}`} >{letter}</span>
            )
          })
        }
      </div>
    </>
  )
}


const NameLogo = ({children, logo}) => {
  
  let name = children.split("");
  const [nameSt, setNameSt] = useState(name);
  
  let keepers = [0,1,3,9,10,11,13]
  let [props, set] = useSpring(() => ({
    from: {
      opacity: 0,
      maxWidth: '0vw'
    },  
    to: [
      { opacity: 1, maxWidth: '4vw' },
      { opacity: 0 },
      { opacity: 1 },
    ]
  }))

  return(
    <>
      <div className="name-split-wrap" >
        <LogoMarkup name={name} keepers={keepers} />
      </div>
    </>
  )
}

export default NameLogo;