import React, { useEffect, useContext, useState } from "react";
import { graphql, useStaticQuery} from 'gatsby';
import Img from 'gatsby-image';
import {ThreeContext} from '../three/ThreeContext';
import { useSpring, animated } from 'react-spring';
import testImg2 from '../images/tempest.jpg';
import ProjectHeaderAlt from '../components/ProjectHeaderAlt';
import { TransitionState } from 'gatsby-plugin-transition-link';
import TwoColumnText from '../components/TwoColumnText';
import MockupGrid from '../components/MockupGrid';
import ContentImageSplit from '../components/ContentImageSplit';
import CenterContent from '../components/CenterContent';
import SidebarContent from '../components/SidebarContent';
import CenterMedia from '../components/CenterMedia';
// import MediaCarousel from '../components/react-spring-slider/MediaCarousel';
import AngledMockupsContent from '../components/AngledMockupsContent';
import ProjectFooter from '../components/ProjectFooter';



export const projectData = {
  name: "Tempest",
  client: "Private",
  url: "/projects/tempest",
  order: 5,
  excerpt: "Lorem Ipsum Excerpt goes here, keep it short",
  image: testImg2,
  listData: [
    {label: 'client', value:"Private"},
    {label: 'services', value:"Generative Design, Architectural Installation, Digital Fabrication"},
    {label: 'role', value:"Designer, Developer, Fabricator"},
    {label: 'technologies', value:"Python, RhinoScript"},
    {label: 'photography by', value:"Michael Nowicki"},
  ],
  services: "Generative Design, Architectural Installation, Digital Fabrication",
  role: 'Designer, Developer, Fabricator',
  color: '#e8854f',
};
  
const Tempest = props => {

  const query = useStaticQuery(graphql`
    query tempestImages {
      tempestDesign: file(name: {eq: "tempest_design"}) {
        id
        childImageSharp {
          fluid{
            ...GatsbyImageSharpFluid
          }
        }
      },
      tempest01: file(name: {eq: "tempest_view1"}) {
        id
        childImageSharp {
          fluid{
            ...GatsbyImageSharpFluid
          }
        }
      },
      tempest02: file(name: {eq: "tempest_view2"}) {
        id
        childImageSharp {
          fluid{
            ...GatsbyImageSharpFluid
          }
        }
      },
      tempest03: file(name: {eq: "tempest_view3"}) {
        id
        childImageSharp {
          fluid{
            ...GatsbyImageSharpFluid
          }
        }
      },
      tempest04: file(name: {eq: "tempest_view4"}) {
        id
        childImageSharp {
          fluid{
            ...GatsbyImageSharpFluid
          }
        }
      },
      footerImage: file(name: {eq: "runaway"}) {
        id
        childImageSharp {
          fluid{
            ...GatsbyImageSharpFluid
          }
        }
      }
      ogImage: file(name: {eq: "tempest"}) {
        id
        childImageSharp {
          fluid{
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  
  let pageMount = props.mount;

  const springProps = useSpring({
    opacity: pageMount ? 1 : 0,
    from: {
      opacity: 0
    }
  });


  return(
    <animated.div style={{opacity: springProps.opacity }} id="detail-container" className={"project-detail"}>
      <ProjectHeaderAlt location={props.location} ogImage={props.data.ogImage.childImageSharp.fluid.src} {...projectData} pageMount={pageMount}>
        <p>This art piece, one of my first parametrically designed and built art pieces, was a large scale wall piece for a private client in Chicago, Illinois. The piece was inspired by the client’s personal background; the couple, a pair of dancers that come from two radically different cultures, had built a relationship that thrives from continuous learning and understanding, and had found balance and harmony in the process. "Tempest" was designed as a visual metaphor for the harmony that can be achieved by two seemingly opposing forces.</p>
      </ProjectHeaderAlt>
      <ContentImageSplit orderSwitch={true} centerAlign={false} image={props.data.tempestDesign.childImageSharp.fluid} heading={'Eye of<br/>the storm'} subheading={'design & build'} >
        <p>Drawing inspiration from both math and nature; I landed on a design that takes the general form of a spiral galaxy or hurricane, with details reminiscent of the cellular divisions found in leaves or insect wings.  Utilizing the programming interface provided by Rhinoceros 3d, I wrote a python script that parametrically generated a 3d model and  fabrication documents to be processed with laser cutters. The end result was an art piece with a feel that is engineered and precise, yet and freeform and organic.</p>
      </ContentImageSplit>
      <div className="container-fluid img-flow">
        <div className="row">
          <div className="col-md-8 mx-auto">
            <Img fluid={props.data.tempest03.childImageSharp.fluid} />
          </div>
          <div className="col-md-8 mr-auto">
            <Img fluid={props.data.tempest02.childImageSharp.fluid} />
          </div>
          <div className="col-md-10 mx-auto">
            <Img fluid={props.data.tempest04.childImageSharp.fluid} />
          </div>
        </div>
      </div>


      <ProjectFooter image={props.data.footerImage.childImageSharp.fluid} />



    </animated.div>

  )
};

export default Tempest;