// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-projects-alxalh-js": () => import("./../src/projects/alxalh.js" /* webpackChunkName: "component---src-projects-alxalh-js" */),
  "component---src-projects-bkb-chicago-js": () => import("./../src/projects/bkb-chicago.js" /* webpackChunkName: "component---src-projects-bkb-chicago-js" */),
  "component---src-projects-learncore-js": () => import("./../src/projects/learncore.js" /* webpackChunkName: "component---src-projects-learncore-js" */),
  "component---src-projects-picture-north-js": () => import("./../src/projects/picture-north.js" /* webpackChunkName: "component---src-projects-picture-north-js" */),
  "component---src-projects-ross-barney-architects-js": () => import("./../src/projects/ross-barney-architects.js" /* webpackChunkName: "component---src-projects-ross-barney-architects-js" */),
  "component---src-projects-runaway-js": () => import("./../src/projects/runaway.js" /* webpackChunkName: "component---src-projects-runaway-js" */),
  "component---src-projects-soucie-horner-js": () => import("./../src/projects/soucie-horner.js" /* webpackChunkName: "component---src-projects-soucie-horner-js" */),
  "component---src-projects-team-marketing-report-js": () => import("./../src/projects/team-marketing-report.js" /* webpackChunkName: "component---src-projects-team-marketing-report-js" */),
  "component---src-projects-tempest-js": () => import("./../src/projects/tempest.js" /* webpackChunkName: "component---src-projects-tempest-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

