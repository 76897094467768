import React, { useState, useEffect } from 'react';
import { useSpring, animated, useTransition } from 'react-spring';
import './NameWrap.scss';


const NameWrap = (prop) => {

  let {children, collapse} = prop;
  
  let name = children.split("");
  // let obj = name.map( (letter, i) => {
  //   return { guid: i, letter}
  // } )
  const [nameSt, setNameSt] = useState(null);
  
  let keepers = [0,1,3,9,10,11,13]
  let [props, set] = useSpring(() => ({
    from: {
      opacity: 0,
      maxWidth: '0vw'
    },  
    to: [
      { opacity: 1, maxWidth: '4vw' },
      { opacity: 0 },
      { opacity: 1 },
    ]
  }))


  

  // useEffect(()=> {
  //   setTimeout(()=>{
  //     setNameSt([]);
  //   }, 5000)
  // }, [])


  return(
    <>
      <div className={"name-split " + collapse } >

        {
          name.map( (letter, index) => {
            let classStr = 'ltr';
              if( keepers.includes(index) ){
                if(index === 9){
                  classStr = 'space';
                } else {
                  classStr = 'ltr-keep';
                }

              }
            return (
              <span className={classStr} key={`letter-${index}`}  >{letter}</span>
            )
          })
        }
      </div>
    </>
  )
}

export default NameWrap;