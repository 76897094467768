/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, {useContext, useState, useEffect, useRef} from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { Scrollbars } from 'react-custom-scrollbars';
import { useTransition } from 'react-spring';
import threeEntryPoint from '../three/threeEntryPoint';
import {ThreeContext} from '../three/ThreeContext';
import "./layout.css"
import '../styles/styles.scss';


import Header from "./header"

const Layout = ({ children, location }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      },
      defaultOgImage: file(name: {eq: "alx_alh_ogimage"}) {
        id
        childImageSharp {
          fluid{
            ...GatsbyImageSharpFluid
          }
        }
      }

    }
  `)

  let mount;
  const { setToProjectFn, threeStarter, setThreeStarter, inProject, setRotateGroup, setTheta, setProjectList } = useContext(ThreeContext);


  const setVh = () => {
    document.querySelector(':root').style
      .setProperty('--vh', window.innerHeight/100 + 'px');
  }

  
  if (typeof window !== `undefined`) {
    window.addEventListener('resize', () => { 
      setVh()
    });

  }

  const scrollRef = useRef(null);


  useEffect(()=>{

    setVh();
    // const threeEntry = threeEntryPoint({mount, pNum});

    // if(location.pathname === '/' && !threeEntry) {
      async function startEntry(){
        const threeEntry = await threeEntryPoint({mount});

        // console.log(threeEntry);
        // console.log('threeEntry finished');
        setToProjectFn({...threeEntry.sceneManager.sceneSubjects});
        setRotateGroup({group: threeEntry.sceneManager.sceneSubjects.rotationGroup, rotateGroup: threeEntry.sceneManager.sceneSubjects.rotateGroup });
        setTheta(threeEntry.sceneManager.sceneSubjects.theta);
        setProjectList(threeEntry.sceneManager.sceneSubjects.projectList);
        setThreeStarter(true);

        return threeEntry
      }
    
  
      startEntry()
        
      // } else {
      //   setCanvasRef(mount);
      // }

      if( typeof window != 'undefined'){
        window.addEventListener('keydown', (e) =>{
          if(document.querySelectorAll('.index-container').length == 0  ){
            console.log('noindex')
            if(e.keyCode === 38){
              scrollRef.current.scrollTop(scrollRef.current.getScrollTop() - 100)
        
            } else if(e.keyCode === 40) {
              scrollRef.current.scrollTop(scrollRef.current.getScrollTop() + 100)
            }
          } 
        })
      }



  }, [])

  //Header component was removed; will want to bring it back eventually <Header siteTitle={data.site.siteMetadata.title} />

  function customizeThumb({style, ...props}){
    const trackColor = { backgroundColor: 'rgba(255,255,255, .4)'};
    return(
      <div {...props} style={{...style, ...trackColor}}/>
    )

  }

  const testTrans = useTransition();

  const handleSSR = () => {
    if (typeof window !== `undefined`) {
      return threeStarter && children
    } else {
      return children
    }
  }



  return (
    <>
      <Scrollbars ref={scrollRef} universal id="scroll-parent" renderThumbVertical={customizeThumb} style={{ width: '100%', height: '100vh' }}>
        <div className={ inProject ? 'layout-root in-project': 'layout-root'}>
          <Header />
          <main>
            {handleSSR()}
          </main>
          <div id="canvas-wrap" ref={node => mount = node} />
        </div>
      </Scrollbars>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
