import React, {useState} from 'react';
import Img from 'gatsby-image';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./SlickCarousel.scss";

const SlickCarousel = props => {
  const {images, backgroundColor} = props;

  const [ currentId, setCurrentId] = useState(1);

  const settings = {
    dots: false,
    variableWidth: true,
    infinite: true,
    slidesToShow:1,
    slidesToScroll:1,
    centerMode: true,
    // onReInit: () => document.querySelector('.slick-track').style.transform = 'translate3d(0,0,0)',
    // afterChange: index => firstSlideTranslation(index),
    beforeChange: (oldIndex, newIndex) => setCurrentId(newIndex + 1),
  }

  // let parentHeight = document.getElementsByClassName('slick-list').clientHeight ? document.getElementsByClassName('slick-list').clientHeight : 400 ;
  let parentHeight = 400;
  let windowWidth = 400;
  if(typeof window !== `undefined`) {
    parentHeight = window.innerHeight ? window.innerHeight * .7 : 400 ;
    windowWidth = window.innerWidth;
  }
  


  return (
    <div style={{backgroundColor}} className="wrap__full">
      <div className="carousel">
        <div className='carousel-status'>
          <span className='current'>{currentId}</span><span className='divider'> | </span><span className='total'>{images.length}</span>
        </div>
      </div>
      <Slider {...settings}>
        {
          images.map( (image, i) => {
            let style = {
              height: parentHeight,
              width: (parentHeight * image.node.childImageSharp.fluid.aspectRatio)
            }
            return(
              <div key={`slick-${i}`}>
                {
                  windowWidth >= 768 ?
                  <Img style={{...style}} imgStyle={{...style}} fluid={image.node.childImageSharp.fluid} />              
                  :
                  <Img  fluid={image.node.childImageSharp.fluid} />
                }
              </div>
            )}
          )
        }
      </Slider>

    </div>
  )
}
export default SlickCarousel;