import * as THREE from 'three';
import { NumberKeyframeTrack } from 'three';
import anime from 'animejs/lib/anime.es.js';
import gsap from 'gsap';
import projectList from '../components/projectList';



//make entire function / file asynchronous

//create items
//load textures
//create shaders
//create planes
//return functions

//utility functions


function RotatingIndex(props){

  let {camera, canvas, scene, mousePosition } = props;
  let isLoaded = false;


  return new Promise( resolve => {

   //console.log('start RotatingIndex Promise');
    async function buildRotatingIndex(){

  
      function viewport(){
        let width = window.innerWidth
        let height = window.innerHeight
        let aspectRatio = width / height
        return {
          width,
          height,
          aspectRatio
        }
      }
      
      const visibleHeightAtZDepth = ( depth, camera ) => {
        // compensate for cameras not positioned at z=0
        const cameraOffset = camera.position.z;
        if ( depth < cameraOffset ) depth -= cameraOffset;
        else depth += cameraOffset;
      
        // vertical fov in radians
        const vFOV = camera.fov * Math.PI / 180; 
      
        // Math.abs to ensure the result is always positive
        return 2 * Math.tan( vFOV / 2 ) * Math.abs( depth );
      };
      
      const visibleWidthAtZDepth = ( depth, camera ) => {
        const height = visibleHeightAtZDepth( depth, camera );
        return height * camera.aspect;
      };
      
      function viewSize(canvas){
        let distance = camera.position.z,
            vFov = camera.fov * Math.PI / 180,
            height = 2 * Math.tan(vFov / 2) * distance,
            width = height * viewport().aspectRatio;
            ////console.log({ width, height, vFov })
            return { width, height, vFov }
      }
      
      const map = (value, start1, stop1, start2, stop2) => {
        return (value - start1) / (stop1 - start1) * (stop2 - start2) + start2
      }
      
      function getViewSize(){
        const fovInRadians = (camera.fov * Math.PI) / 180;
        const height = Math.abs(
          camera.position.z * Math.tan(fovInRadians / 2) * 2
        );
       //console.log('vrepo', height * camera.aspect, height);
        return { width: height * camera.aspect, height };
      }

      //create vars / objects that need to be in shared scope

      
      const rotationGroup = new THREE.Group();
      window.rg = rotationGroup;
      let viewSizeObj = viewSize();
      // let viewSize = getViewSize();
      let uniformSet = [];
      let meshes = [];
      let shaders = [];
      let timer = 0;
      let state = "";
      let animating = false;
      let tween;


      let itemsElements = () => {
        // const items = [...document.body.querySelectorAll('.project-container')];
        const items = projectList;
      
        return items.map( (item, index) => ({
          element: item,
          img: item.image || null,
          index: index,
      
        }))
      
      }
      
      function loadTexture(loader, url, index){
        return new Promise((resolve, reject) => {
          if(!url){
            resolve({ texture: null, index})
            return
          }
          loader.load(
            url,
            texture => {
              resolve({texture, index})
            },
            undefined,
            error => {
              this.console.error('An error happened.', error)
              reject(error)
            }
          )
        })
      }

      let items = new itemsElements();
      let leftEdgeProject = 0;
      let rightEdgeProject = 0; 
      let midPoint = Math.floor(items.length / 2);
      let groupOffset;
      let theta = 0;


      function initEffectShell(){
        let promises = [];

        const THREEtextureLoader = new THREE.TextureLoader();
        items.forEach((item, index) => {
          promises.push(
            loadTexture(
              THREEtextureLoader,
              item.img ? item.img : NumberKeyframeTrack,
              index
            )
          )
        });

        return new Promise((resolve, reject) => {
          Promise.all(promises).then(promises => {
            promises.forEach((promise, index) => {
              items[index].texture = promise.texture;
              // items[index].texture.wrapS = items[index].texture.wrapT = THREE.RepeatWrapping;
              items[index].texture.wrapS = items[index].texture.wrapT = THREE.ClampToEdgeWrapping;
              items[index].texture.minFilter = THREE.LinearFilter;
              items[index].texture.offset.x = 0.5;
              items[index].texture.repeat = 2;
              items[index].texture.generateMipmaps = false;
              items[index].texture.needsUpdate = true;
              items[index].texture.updateMatrix();
            })
            resolve()
          })
        })
      }

      // initEffectShell().then(() => {
      //   isLoaded = true;
      //   ////console.log(items);
      // });

      let uMeshPositionYCalc;
      let fullscreenScale = 2;

      
      //orignal planeWidth mult = .8 setting to .9 for testing
      let planeWidth = viewSizeObj.width * .9 * .75;
      let planeOffset = ((viewSizeObj.width * .9) - planeWidth) / 2  ; 
    
      let planeHeight = planeWidth / 1.7777778;

      if(viewport().width <= 500) {
        camera.position.setZ(1.25);
        console.log(viewSizeObj);
        viewSizeObj = viewSize();
        console.log(viewSizeObj);
        uMeshPositionYCalc = viewSizeObj.width * fullscreenScale / 1.7778 / 2 - viewSizeObj.height / 2;
        console.log(uMeshPositionYCalc);

        planeWidth = viewSizeObj.width * fullscreenScale * .75;
        planeHeight = planeWidth / 1.7777778;
        // fullscreenScale = .5;
        //

        shaders.forEach(shader => {
          shader.uniforms.uMeshPosition.value.y = uMeshPositionYCalc;
        })
      } else {
        uMeshPositionYCalc = viewSizeObj.width / 1.7778 / 2 - viewSizeObj.height / 2;

      }



      const init = await initEffectShell();
      const planes = await createPlanes();
      
     //console.log('end init create planes');
      window.theta = theta;



      
      
      function createPlanes(){
       //console.log('start create planes')
        return new Promise((resolve => {

          let divisor = 1.5;
        
          theta = Math.PI / divisor / items.length;

          //to have images spread out, divide by divisor. To keep very close, divide by 2
          let halfTheta = theta / 2;
        
          // working backwards to figure out theta based on plane size, not arbitrary increment of circle
        
          
        
          //halfTheta / 1.1 for original design. Change value to add more distance between corners of images
          let adjacentSide = (planeHeight / 2) / Math.tan(halfTheta / 1.1);
          groupOffset = adjacentSide + (planeWidth / 2);
        
          //to center planes, remove planeOffset
          rotationGroup.translateX(  groupOffset  );
          // rotationGroup.translateX(  groupOffset + planeOffset );
        
          //calculate rotateX angle and translateZ for non central planes
          //triangle forming two arms to center points is (180 - (theta / 2)) / 2
        
          let eqAngle = (Math.PI - (theta)) / 2;
          let altTheta = (Math.PI / divisor) - eqAngle;
          let hypot = Math.sin(theta) * (groupOffset  / Math.sin(eqAngle));
          let xTranslate = Math.sin(altTheta) / hypot;

        
          const vertexShader = `
          uniform float uProgress;
          uniform vec2 uMeshScale;
          uniform vec3 uMeshPosition;
          uniform vec2 uViewSize;
          uniform float uTime;
          uniform float uWaveMult;
          varying vec2 vUv;
          #define M_PI 3.1415926535897932384626433832795
          
          void main(){

            vec3 pos = position.xyz;
        
            //activation
        
            // float activation = uv.x;
        
            float activation = (+uv.x-uv.y+1.)/2.;
        
            float latestStart = 0.9;
            float startAt = activation * latestStart;
            float vertexProgress = smoothstep(startAt, 1.0, uProgress);
        
            // float flippedX = -pos.x;
            // pos.x = mix(pos.x,flippedX, vertexProgress);
            // pos.z += vertexProgress;
        
            //scale to page view size/page size
            vec2 scaleToViewSize = (uViewSize / uMeshScale)   - 1.;
            // scaleToViewSize.y = scaleToViewSize.y / 2.;
            vec2 scale = vec2(
              1. + scaleToViewSize * vertexProgress
            );
            pos.xy *= scale;
            float waveAdjust = (pos.x * 2.) + (pos.y * 2.);
            float wave = (sin( pos.x * M_PI + waveAdjust + uTime * .001 ) * .05) * uWaveMult;
            // float wave = sin( pos.x + uTime * .001);
            pos.z = pos.z + wave;
        
            //move to center
            // pos.y += -uMeshPosition.y * vertexProgress - (uMeshPosition.y * 0.4);
            pos.y += -uMeshPosition.y * vertexProgress;
            pos.x += -uMeshPosition.x * vertexProgress;
            pos.z += -uMeshPosition.z ;
            // pos.x += pos.x * 0.5;
        
            //animate alpha
        
            gl_Position = projectionMatrix * modelViewMatrix * vec4(pos,1.);
            vUv = uv;
          }
          `;
          const fragmentShader = `
            uniform sampler2D uTexture;
            uniform vec3 uColor;
            uniform vec2 uOffset;
            uniform float uAlpha;
            varying vec2 vUv;
            

            // vec3 rgbShift(sampler2D texture, vec2 uv, vec2 offset){
            //   float r = texture2D(uTexture, vUv + uOffset ).r;
            //   vec2 gb = texture2D(uTexture, vUv).gb;
            //   return vec3(r, gb);
            // }

            // vec3 testScale(sampler2D texture, vec2 uv, vec2 scale){
            //   vec2 texCoords = (vUv - 0.5) * scale + (0.5 * scale);
            //   float r = texture2D(uTexture, texCoords).r;
            //   vec2 gb = texture2D(uTexture, vUv).gb;
            //   return vec3(r,gb);
            // }


            void main(){
        
            
              // vec3 color = rgbShift(uTexture,vUv, uOffset);
              // vec3 color = testScale(uTexture,vUv, vec2(1.01, 1.01));

              // vec3 color = uColor / 255.;
              // color = mix(color, vec3(0.9, 0.5, 0.5), vUv.x);
              // gl_FragColor = vec4(vUv.x, vUv.y, 0.5, 1.0);
              gl_FragColor = vec4(texture2D(uTexture, vUv).rgb, uAlpha);
              // gl_FragColor = vec4(texture2D(uTexture, vUv).rgb,1.);
              // gl_FragColor = vec4(color, uAlpha);
            }
          `;
        
        
        
          let naturalAspect = 1.77777778;

          const segments = 32;
        
          let plane = new THREE.PlaneBufferGeometry( planeWidth, planeHeight, segments, segments);
        
        
          let uvs = plane.attributes.uv.array;
          
          // for( let i = 0; i < uvs.length; i+= 2){
          //   // uvs[i] -= 0.5;
          //   uvs[i+1] /= ratioAdjustment / naturalAspect;
          // }
        
          let planeCount = 0;
          let splitCount = items.length - 1;
          
        
          leftEdgeProject = midPoint;
          rightEdgeProject = midPoint + 1;
        
          items.forEach( (item, i) => {
        
            let uniforms = {
              uTexture: item.texture,
              uProgress: new THREE.Uniform(0),
              uViewSize: new THREE.Uniform(new THREE.Vector2(1, 1)),
              uMeshScale: new THREE.Uniform(new THREE.Vector2(1, 1)),
              uMeshPosition: new THREE.Uniform(new THREE.Vector3(0, 0, 0)),
              uColor: new THREE.Uniform(new THREE.Vector3(20, 20, 20)),
              uOffset: new THREE.Uniform(new THREE.Vector2(.2, .2)),
              map: item.texture,
              uAlpha: new THREE.Uniform(0),
              uTime: new THREE.Uniform(0),
              uWaveMult: new THREE.Uniform(0),
            }
            let mat = new THREE.ShaderMaterial({
              transparent: true,
              uniforms: uniforms,
              vertexShader: vertexShader,
              fragmentShader: fragmentShader,
              side:THREE.DoubleSide,
              defines: {
                USE_MAP: ''
              }
            });
            shaders[i] = mat;
        
            shaders[i].uniforms.uTexture.value = item.texture;
        
            //following will set image as fraction of width
            // shaders[i].uniforms.uViewSize.value.x = viewSizeObj.width * .7 ;
            // shaders[i].uniforms.uViewSize.value.y = viewSizeObj.width * .7 / 1.7778;
            
            shaders[i].uniforms.uMeshScale.value.x = planeWidth;
            shaders[i].uniforms.uMeshScale.value.y = planeHeight;
            
            //comment following line to center planes
            // shaders[i].uniforms.uMeshPosition.value.x = planeOffset ;
            // shaders[i].uniforms.uMeshPosition.value.x = viewSizeObj.width * groupOffset * 1.3 - planeWidth / 2;

            //setting below will set plane to full width, maintain aspect ratio, and fix image to top of screen

            // uMeshPositionYCalc = viewSizeObj.width / 1.7778 / 2 - viewSizeObj.height / 2;
            shaders[i].uniforms.uMeshPosition.value.y = uMeshPositionYCalc;

            if(viewport().width <= 500) {
              shaders[i].uniforms.uViewSize.value.x = viewSizeObj.width * fullscreenScale ;
              shaders[i].uniforms.uViewSize.value.y = viewSizeObj.width * fullscreenScale / 1.7778;

            } else {
              shaders[i].uniforms.uViewSize.value.x = viewSizeObj.width ;
              shaders[i].uniforms.uViewSize.value.y = viewSizeObj.width / 1.7778;

            }

            console.log(uMeshPositionYCalc)

        
            // shaders[i].uniforms.uMeshPosition.value.y = -planeHeight / 2;
        
        
        
        
            let mesh = new THREE.Mesh(plane, mat);
            meshes.push(mesh);

            let copyCount = divisor * 2 - 1;
            while(copyCount > 0){
              let meshCopy = mesh.clone()
              meshCopy.rotateZ((i * theta) + (copyCount * Math.PI / divisor));
              meshCopy.translateX( -groupOffset);
              rotationGroup.add(meshCopy)
              copyCount--;

            }


            //reorganize order to make planes loop while on a limited arc
        
            // if( planeCount > midPoint ){
            //   mesh.rotateZ( ( splitCount - midPoint) * -theta);
            //   // mesh.translateZ( xTranslate * ( splitCount - midPoint) );
            //   // mesh.rotateX(altTheta * ( splitCount - midPoint) );
            //   splitCount--;
            // } else {
              mesh.rotateZ(i * theta);
              // mesh.translateZ( xTranslate * i );
              // mesh.rotateX( altTheta * i );
        
            // }
            mesh.translateX(  -groupOffset);


            // mesh.translateX( -viewSizeObj.width * groupOffset);
            rotationGroup.add(mesh);



            planeCount++;
          })
          // updateShaders(0);
          scene.add(rotationGroup);
          // camera.position.z = 50;
          //console.log('create planes promise resolved');
          // let rotationGroup2 = rotationGroup.clone();
          // rotationGroup.rotation.z = Math.PI;
          // scene.add(rotationGroup2);
      
          resolve();
          
          
        }))
      }

      
      function updateShaders(index, page) {
        // if(animating) return;
        animating = true;
        let duration = 0.5;
        let curOpacity;
        let curWave;
        let othersOpacity
        let othersWave;
        let uProgress;
        let uMeshPosition;

        
        if(page === 'profile'){
          curOpacity = curWave = othersOpacity = othersWave = uProgress = uMeshPosition =  0;
          duration = 1;
          // curWave = 1;
          // othersOpacity = 0.1;
          // othersWave = 0;
          // uProgress = uMeshPosition = 0
        } else {
          curOpacity = 1;
          curWave = 1;
          othersOpacity = 0.1;
          othersWave = 0;
          uProgress = uMeshPosition = 0

        }
      
        shaders.forEach( ( item, i) => {
          if(i === index){
            gsap.to(item.uniforms.uAlpha, duration, {
              value: curOpacity,
              onComplete: () => {
                animating = false;
                state = "index";
              }
            });
            gsap.to(item.uniforms.uWaveMult, duration, {
              value: curWave,
              onComplete: () => {
                animating = false;
                state = "index";
              }
            });
            
          } else {
            gsap.to(item.uniforms.uAlpha, duration, {
              value: othersOpacity,
              onComplete: () => {
                animating = false;
                state = "index";
              }
            })
            gsap.to(item.uniforms.uWaveMult, duration, {
              value: othersWave,
              onComplete: () => {
                animating = false;
                state = "index";
              }
            });

            
          }
          gsap.to(item.uniforms.uProgress, duration, {
            value: uProgress,
            onComplete: () => {
              animating = false;
              state = "index";
            }
          })
          gsap.to(item.uniforms.uMeshPosition.value, duration, {
            z: uMeshPosition,
            onComplete: () => {
              animating = false;
              state = "index";
            }
          })

        })

      }

      // window.updateShaders = updateShaders;
      
      function rotateGroup(angle){
        console.log(rotationGroup.rotation.z);
        // rotationGroup.rotateZ(angle);
      
        // rotationGroup.rotation.set(0,0, angle);
        // rotationGroup.rotation.z = angle;

        
      
      }
      window.meshes = meshes;

      window.rotationGroup = rotationGroup;

      window.rotateGroup = rotateGroup;

      let tweens = [];

      window.tweens = tweens;

      let betweenProjects = false;

      function nextProjectSetup(currentId, nextId, isVisible){
        animating = true;

        let duration = 1;

        if(isVisible){
          document.getElementById('canvas-wrap').classList.remove('in-project');
          rotationGroup.rotation.z = -theta * nextId;
          shaders[currentId].uniforms.uAlpha.value = 0;
          shaders[nextId].uniforms.uProgress.value = 2;
          // shaders[nextId].uniforms.uAlpha.value = 1;
          shaders[nextId].uniforms.uMeshPosition.value.z = 0;
          shaders[nextId].uniforms.uMeshPosition.value.y = -uMeshPositionYCalc ;

          gsap.to(shaders[nextId].uniforms.uAlpha, duration, {
            value: 1,
            onComplete: () => {
              animating = false;
            }
  
          })

        } else {
          document.getElementById('canvas-wrap').classList.add('in-project');
          rotationGroup.rotation.z = -theta * currentId;

          // shaders[currentId].uniforms.uAlpha.value = 1;
          // shaders[nextId].uniforms.uProgress.value = 0;
          // shaders[nextId].uniforms.uAlpha.value = 0;
          // shaders[nextId].uniforms.uMeshPosition.value.z = 100;
          // shaders[nextId].uniforms.uMeshPosition.value.y = planeHeight;
          shaders[currentId].uniforms.uAlpha.value = 1;
          gsap.to(shaders[nextId].uniforms.uAlpha, duration, {
            value: 0,
            onComplete: () => {
              animating = false;
            }
  
          })

          gsap.to(shaders[nextId].uniforms.uMeshPosition.value, duration, {
            y: uMeshPositionYCalc,
            onComplete: () => {
              animating = false;
            }
  
          })


        }
      }

      window.nps = nextProjectSetup;

      function nextProjectClick(currentId, nextId){
        console.log('in npc');
        animating = true;
        betweenProjects = true;
        let duration = 1;
        gsap.to(shaders[nextId].uniforms.uMeshPosition.value, duration, {
          z: 0,
          y: uMeshPositionYCalc,
          onComplete: () => {
            animating = false;
            state = "index";
          }
        })



        betweenProjects = false;
        
        

      }
      
      window.npc = nextProjectClick;
      
      function toNextProject(currentId, nextId, inProject){

        console.log('to next project')
        animating = true;
        betweenProjects = true;

        let duration =  1;

        // toFullscreen(currentId+1)

        let nextProjectTween;

        // anime({
        //   targets: shaders[currentId].uniforms.uAlpha,
        //   value: 0,
        // })

        // shaders.forEach((item, i) => {
        //   if(i === currentId){
        //     gsap.to(item.uniforms.uAlpha, duration, {
        //       value: 0,
        //       onUpdate: ()=> {
        //         console.log(item.uniforms.uAlpha.value);
        //       },
        //       onComplete: () => {
        //         animating = false;
        //         state = "fullscreen";
        //         betweenProjects = false;
        //       }
        //     })
        //   }
        // });
        // shaders[currentId].uniforms.uProgress.value = 0;
        // shaders[currentId].uniforms.uAlpha.value = 0;
        // shaders[currentId].uniforms.uMeshPosition.z = 1;


        anime({
          targets: rotationGroup.rotation,
          z: -theta * nextId,
          easing: 'easeInOutExpo',

        })



        nextProjectTween = gsap.to(shaders[currentId].uniforms.uProgress, duration, {
          value: 0,
          onComplete: () => {
            animating = false;
            state = "fullscreen";
          }
        });
        nextProjectTween = gsap.to(shaders[currentId].uniforms.uAlpha, duration, {
          value: 0,
          onUpdate: ()=> {
          },
          onComplete: () => {
            animating = false;
            state = "fullscreen";
          }
        })
        nextProjectTween = gsap.to(shaders[currentId].uniforms.uMeshPosition.value, duration, {
          z: 1,
          onComplete: () => {
            animating = false;
            state = "fullscreen";
          }
        })


        nextProjectTween = gsap.to(shaders[nextId].uniforms.uProgress, duration, {
          value: 1,
          onComplete: () => {
            animating = false;
            state = "fullscreen";
          }
        });
        nextProjectTween = gsap.to(shaders[nextId].uniforms.uAlpha, duration, {
          value: 1,
          onComplete: () => {
            animating = false;
            state = "fullscreen";
          }
        })
        nextProjectTween = gsap.to(shaders[nextId].uniforms.uMeshPosition.value, duration, {
          z: 0,
          onComplete: () => {
            animating = false;
            state = "fullscreen";
            betweenProjects = false;
          }
        })
        
      }


      function toFullscreen(index, page){

        //do not add animating as a condition to line below - will break transitions between projects in rapid succession
        if( page === state || betweenProjects ) return;
        console.log('fullscreen triggered');
        rotationGroup.rotation.z = -theta * index;

        // gsap.killTweensOf(shaders)

        return new Promise(resolve => {
          // if(state === "fullscreen" || animating) return;
          animating = true;
          let duration = 1;
    
          shaders.forEach( ( item, i) => {
            if(i === index){
              tweens[i] = gsap.to(item.uniforms.uProgress, duration, {
                value: 1,
                onComplete: () => {
                  animating = false;
                  state = "fullscreen";
                }
              });
              tweens[i] = gsap.to(item.uniforms.uAlpha, duration, {
                value: 1,
                onComplete: () => {
                  animating = false;
                  state = "fullscreen";
                }
              })
              tweens[i] = gsap.to(item.uniforms.uMeshPosition.value, duration, {
                z: 0,
                onComplete: () => {
                  animating = false;
                  state = "fullscreen";
                }
              })

              
            } else {
              tweens[i] = gsap.to(item.uniforms.uAlpha, duration, {
                value: 0,
                onComplete: () => {
                  animating = false;
                  state = "fullscreen";
                }
              });
              tweens[i] = gsap.to(item.uniforms.uMeshPosition.value, duration, {
                z: 1,
                onComplete: () => {
                  animating = false;
                  state = "fullscreen";
                }
              })
              tweens[i] = gsap.to(item.uniforms.uProgress, duration, {
                value: 0,
                onComplete: () => {
                  animating = false;
                  state = "fullscreen";
                }
              })
              
            }
            tweens[i] = gsap.to(item.uniforms.uWaveMult, duration, {
              value: 0,
              onComplete: () => {
                animating = false;
                state = "fullscreen";
              }
            })
            resolve(state = "fullscreen")
           //console.log(state);
          })
          

        })
      }  
      function toIndex(index, page){
        // gsap.killTweensOf(items)
        if(page === state || betweenProjects ) return;
        // if(state === "index" || animating) return;
        animating = true;
        let duration = 1;
  
        shaders.forEach( (item, i) => {
          if(i === index){
            tween = gsap.to(item.uniforms.uProgress, duration, {
              value: 0,
              onComplete: () => {
                animating = false;
                state = "index";
              }
            });
            tween = gsap.to(item.uniforms.uAlpha, duration, {
              value: .9,
              onComplete: () => {
                animating = false;
                state = "index";
              }
            })
            tween = gsap.to(item.uniforms.uMeshPosition.value, duration, {
              z: 0,
              onComplete: () => {
                animating = false;
                state = "index";
              }
            })
            tween = gsap.to(item.uniforms.uWaveMult, duration, {
              value: 1,
              onComplete: () => {
                animating = false;
                state = "index";
              }
            });


            
          } else {
            tween = gsap.to(item.uniforms.uAlpha, duration, {
              value: .2,
              onComplete: () => {
                animating = false;
                state = "index";
              }
            })
            tween = gsap.to(item.uniforms.uMeshPosition.value, duration, {
              z: 0,
              onComplete: () => {
                animating = false;
                state = "index";
              }
            })
            tween = gsap.to(item.uniforms.uWaveMult, duration, {
              value: 0,
              onComplete: () => {
                animating = false;
                state = "index";
              }
            });
          }
        })
        // updateShaders(index);
      }
        
          
      function transitionToProject(index, inProject){
        //adding animating as a condition below will break the change of the header image while using the browser buttons
        if(!inProject || betweenProjects ) return;
        if(shaders.length > 0){
          ////console.log('ttp', item);
          // updateMesh(index, item);
         //console.log('ttp', index);
          toFullscreen(index);
          // shaders[index].color.setHex(0x00ff00);
          ////console.log('shaders',shaders);
          ////console.log('index', index);
          shaders[index].materialNeedsUpdate = true;
  
        }
  
      }

      window.shaders = shaders;
      window.timer = timer;
      function update(time){
        shaders.forEach(shader => {
          shader.uniforms.uTime.value = time;
        })

      }

            
     //console.log('end RotatingIndex Promise');
      resolve({ projectList, update, transitionToProject, toIndex, updateShaders, toNextProject, rotationGroup, rotateGroup, theta,nextProjectSetup, nextProjectClick});
    }
    buildRotatingIndex();

  })

}

export default RotatingIndex;

