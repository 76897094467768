import React from 'react';
import './SidebarContent.scss';

export default props => {
  const { subheading, heading, children } = props;
  return(
    <div className="wrap__full">
      <div className="container-fluid">
        <div className="sidebar-content">
          <div className="content-wrap">
            <div className="content">
              { subheading && <h4 className="subheading">{subheading}</h4>}
              { heading && <h2 dangerouslySetInnerHTML={{__html: heading}} />}
              { children}
            </div>
          </div>
          <div className="image-wrap">
            <img src="https://picsum.photos/1200/3600" alt=""/>
          </div>

        </div>
      </div>
    </div>
  )
}